import React from 'react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Metamask from './Metamask'
import TrustWallet from './TrustWallet'
import WalletConnect from './WalletConnect'
import BinanceWallet from './BinanceWallet'
import SafePal from './SafePal'
import Coin98 from './Coin98'
import Web3 from 'web3'
import TokenPocket from './TokenPocket.js'
import MathWallet from './MathWallet'
import $ from 'jquery'
import Chains from '../nativeCurrencyList.json'

function Nav(props) {
   console.log("local: ");
   console.log(window.localStorage.getItem('selectedChain'));
const [chainIndex,setChainIndex]= useState(window.localStorage.getItem('selectedChain')?window.localStorage.getItem('selectedChain'):0); //0: Ethereum, 1: BSC, 2: Polygon
window.localStorage.setItem('selectedChain',chainIndex);
function chainSelect(i){
    setChainIndex(i);
    window.localStorage.setItem('selectedChain',chainIndex);
    window.location.reload(this);
}
function handleNavClick(){
    window.localStorage.setItem('selectedChain',chainIndex);
    window.location.reload(true);
    console.log("in Nav page handle click ....^|");
}
useEffect(()=>{
    console.log("check chain: "+ Chains[chainIndex].Id);
    window.localStorage.setItem('selectedChain',chainIndex);
},[chainIndex])

   let metamask;
   if(window.localStorage.getItem("connectionProvider") == 'metamask' && window.localStorage.getItem("connectedWalletddress")!= null)
   {
    metamask = <Metamask status="connected"/>
   }
   else
   {
    metamask = <Metamask />
   }
      
   let tokenpocket; 
   if(window.localStorage.getItem("connectionProvider") == 'tokenPocket' && window.localStorage.getItem("connectedWalletddress")!= null)
   {
    tokenpocket = <TokenPocket status="connected"/>
   }
   else
   {
    tokenpocket = <TokenPocket />
   }

  return (
   
   
        <>
         

        <nav className="navbar navbar-expand-lg bg-light" onClick={props.onClick}>
          <div className="container">
              <div className="row">
                  <div className="col-md-2 col-sm-2 col-xs-2">
                      <Link to="/" className="navbar-brand"><img src="assets/img/logo.png" /></Link>
                  </div>

                  <div className="col-md-10 col-sm-10 col-xs-10">
                      <button className="navbar-toggler" style={{ background: '#fcc6fc', float: 'right' }} type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                          <span className="navbar-toggler-icon"></span>
                      </button>
                      <div className="collapse navbar-collapse float-end" id="navbarNavAltMarkup">
                          <div className="navbar-nav">
                              <Link to="/" className="nav-link pd-top-25" aria-current="page">Home</Link>
                              <Link to="/about" className="nav-link pd-top-25">About Us</Link>
                              <Link to="/how-it-works" className="nav-link pd-top-25">How It Works</Link>
                              <Link to="/swap" className="nav-link pd-top-25">Swap</Link>
                              <Link to="/myreward" className="nav-link pd-top-25">My Reward</Link>
                              <div className="btn-group">
                                  <button className="btn  dropdown-toggle my_blockchain nav-link " type="button" data-bs-toggle="dropdown" data-bs-auto-close="inside" aria-expanded="false">
                                      <img width="30" src={Chains[chainIndex].Image} /> {Chains[chainIndex].Name}
                                  </button>
                                  <ul className="dropdown-menu">
                                    {
                                        Chains?.map((e,i) => {
                                            if(Chains[i].status != 0) 
                                            return(
                                                <li><Link className="dropdown-item" to="#" onClick={(e)=>chainSelect(i)} onChange={props.onChange}> <img width="30" src={Chains[i].Image} /> {Chains[i].Name}</Link></li>     
                                            );
                                        })
                                    }
                                      
                                  </ul>
                              </div>
                              <div className="nav-link  pd-top-25 walletAddress">
                              <div id="walletAddress"></div>
                              </div>
                              <a href="javascript:;" id="WalletButton" className="nav-link nav_connect_wallet" data-bs-toggle="modal" data-bs-target="#connectWallet" ><img src="assets/img/connect.jpg" /></a>
                              
                                   </div>
                      </div>
                  </div>
              </div>
          </div>
      </nav>

      <div className="modal fade" id="connectWallet"  tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h1 className="modal-title fs-5" id="staticBackdropLabel" style={{ color: '#ccc' }}>Connect Wallet</h1>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body" id="connectWalletModalBody">
                          <div className="row">
                              <div className="col-md-12">
                                  <div className="walletList">                   

                                  { metamask }
                                  
                                  </div>
                              </div>
                              <div className="col-md-12">
                                  <div className="walletList">
                                    <TrustWallet />
                                     
                                  </div>
                              </div>
                              {
                                /*<div className="col-md-12">
                                  <div className="walletList">
                                    <WalletConnect />
                                      
                                  </div>
                              </div> */
                              
                              }
                              <div className="col-md-12">
                                  <div className="walletList">
                                      <MathWallet />
                                  </div>
                              </div>
                              <div className="col-md-12">
                                  <div className="walletList">
                                  { tokenpocket }
                                     
                                  </div>
                              </div>
                              <div className="col-md-12">
                                  <div className="walletList">
                                      <SafePal />
                                  </div>
                              </div>
                              <div className="col-md-12">
                                  <div className="walletList">
                                      <Coin98 />
                                  </div>
                              </div>
                              <div className="col-md-12">
                                  <div className="walletList">
                                      <BinanceWallet />
                                  </div>
                              </div>
                          </div>
                      </div>

                  </div>
              </div>
          </div>
      
          </>

    
  )
}
export default Nav
