import React from 'react'
import { Link } from 'react-router-dom'
import FooterNav from '../components/FooterNav'
import Nav from '../components/Nav'
export default function Faq() {
  return (
  
    <React.Fragment>
        
        <Nav />

<div className="home-about faq">
<div className="container">
   <div className="row">
   <h1>Frequently Asked Questions</h1>
   <div className="col-md-2"></div>
   <div className="col-md-8 ">
   <h2>What are Cryptocurrency and Crypto Exchanges?</h2>
   <p>Cryptocurrency — also known as crypto — is a digital currency designed to work as a medium of exchange. It uses cryptography to secure and verify transactions, as well as to control the creation of new units of a particular digital currency. </p>
<p>The cryptography makes it nearly impossible to counterfeit or double-spend. Cryptocurrency is a digital payment system that doesn't rely on banks to verify transactions. It’s a peer-to-peer system that can enable anyone anywhere to send and receive payments. Instead of being physical money carried around and exchanged in the real world, cryptocurrency payments exist purely as digital entries to an online database describing specific transactions. </p>
<p>Cryptocurrencies are distinguished from fiat currencies like the United States dollar or the British pound because a central authority does not issue them, making them potentially impervious to government intervention, manipulation, or collapse. </p>
<p>There are many varieties of cryptocurrency. You have probably read about some of the most popular ones, such as Bitcoin, Litecoin, and Ethereum. </p>
<p>There are two ways to buy, sell, trade, or exchange your cryptocurrency. One is through a centralized crypto exchange and the second is through a decentralized crypto exchange. </p>
<p style={{fontWeight:'bold', textDecoration:'underline'}}>Centralized Exchange</p>
<p>As suggested by the name, centralized cryptocurrency exchanges act as a third-party between a buyer and a seller. A centralized exchange is a middle-man that processes transactions between users and allow for the conversion of fiat currencies into cryptocurrencies. </p>
<p>The advantages of centralized exchanges are that they are user friendly and reliable. At the same time, there is a high risk of hacking, and the transactions fees are high too. </p>
<p style={{fontWeight:'bold', textDecoration:'underline'}}>Decentralized Exchange</p>
<p>Decentralized cryptocurrency exchanges allow users to execute peer-to-peer transactions without the need for a third party. However, decentralized exchanges do not facilitate the trading of fiat currencies for cryptocurrencies. Some of the advantages of decentralized exchanges are: </p>
 
	<p><u>Anonymity:</u> Unlike centralized cryptocurrency exchanges, which require customers to fill out certain details including know-your-customer (KYC) details while opening an account, decentralized exchanges are anonymous and offer complete privacy. </p>
	<p><u>No hacking risk:</u> Decentralized exchanges form a good alternative for investors concerned about hacking risks, since the users of decentralized exchanges do not need to transfer their assets to a third party that may get hacked. </p>
	<p><u>Preventing Market Manipulation:</u> Since the interaction is peer-to-peer in decentralized exchanges, crypto participants are protected from market manipulation. </p>

   
   </div>
   
   
  
</div>
</div>
</div>

     <FooterNav />
    
    

    </React.Fragment>
  )
}
