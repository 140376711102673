import React from 'react'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import Web3 from 'web3';
import $ from 'jquery'


export default function TrustWallet(){
  
  const [walletAddress, setWalletAddress] = useState("");
  const { trustWallet } = window;
   useEffect(()=>{
    getCurrentWalletConnected();
   });
  
    const ethEnabled = async () => {
      if(trustWallet){
        trustWallet.request({ method: 'eth_requestAccounts' })
      .then((accounts) => {
                window.web3 = new Web3(trustWallet);
                console.log(accounts);
                window.$('#connectWallet').modal('hide');
                //$('#connectTrust').modal('hide')
               // $('#connectTrust').modal().hide();
              //  $("#walletAddress").html(accounts[0].substring(0,5)+"..."+accounts[0].substring(38));
                if($("#WalletButton").length)
                 
                //$("#WalletButton").html('<a href="connect-wallet.php" class="default-btn move-right"><span>Connected</span> </a>');
                $("#WalletButton").html('<div class="nav-link  pd-top-25 walletAddress">'+accounts[0].substring(0,5)+"..."+accounts[0].substring(38)+'</div>');
                
            
                  }).catch((error) => {
                            console.log("promise catches error! ");
                            console.error(error)
                            //alert(error)
                               });
         return true;
      }
      else
      {
            console.log("Please install TrustWallet");
            
           const Swal2 = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success'
      },
      buttonsStyling: false
    });
    
     Swal2.fire({
            icon: 'warning',
            title: 'Trust wallet not found',
            text: 'Please install Trust Wallet!',
            html: '<a href="https://chromewebstore.google.com/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph" target="_blank" style="color: #00F; ">Click here to download Trust Wallet into your browser</a> ',
            confirmButtonText: 'Got it !'
        }); 
      }
      return false;
    };
    const change_account = async () => { 
       if(trustWallet)
       {
        await trustWallet.request({
            method: "wallet_requestPermissions",
            params: [{
                eth_accounts: {}
            }]
        }).then((result)=>{ ethEnabled(); }); 
      }

      };
    
    function connectTrust(){
      if(trustWallet){  
    trustWallet.request({ method: 'eth_chainId' })
                            .then((chain_id) => {
                                         console.log(chain_id);
                                         //alert(chain_id);
                                             if(chain_id == 0x1 || chain_id == 56)
                                          {
                                                    ethEnabled();
                                              }
                                            else
                                            {
                                              //Swal.fire("This app currently support only Mainnet or Rinkeby Testnet ! Please change network");      
                                              alert('This app currently support only Ethereum and Binance Smart Chain ! Please change network');
                                             }   
      
                                        }).catch((error) => {
                                                    console.log("promise catches error! ");
                                                    console.error(error)
                                                    });

                      }   
             else
                {
                  console.log("Please install TrustWallet");
            
                  const Swal2 = Swal.mixin({
             customClass: {
               confirmButton: 'btn btn-success'
             },
             buttonsStyling: false
           });
           
            Swal2.fire({
                   icon: 'warning',
                   title: 'Trust wallet not found',
                   text: 'Please install Trust Wallet!',
                   html: '<a href="https://chromewebstore.google.com/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph" target="_blank" style="color: #00F; ">Click here to download Trust Wallet into your browser</a> ',
                   confirmButtonText: 'Got it !'
               }); 
                }          
    }


    const getCurrentWalletConnected = async() => {
      if(typeof window != "undefined" && typeof trustWallet !="undefined" ){
       try {
         // Metamask installed 
         const accounts = await trustWallet.request({ method: "eth_accounts"});
         if(accounts.length >0){
         console.log(accounts[0]);
         //alert("Your Metamask Wallet Connected Succesfully...")
        //document.getElementById('connectWallet').style.display = "none";
        //document.getElementsByClassName('modal-backdrop').style.display = "none";
        //document.getElementById('WalletButton').style.display = "none";
        $("#WalletButton").html('<div class="nav-link  pd-top-25 walletAddress">'+accounts[0].substring(0,5)+"..."+accounts[0].substring(38)+'</div>');
                
      //  window.location.reload();
        setWalletAddress(accounts[0]);
         } else{
          console.log("Connect Metamask using Connect button...");
         }
         
          }catch(err){
               console.error(err.message);
          }
      }else{
        
        console.log("Please install metamask ");
      }
   }


    return(  
        <>
         <a href="#" onClick={connectTrust}><img src="assets/img/trust.png" /> Trust Wallet</a>
        </>  
    )
}