import React from 'react'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import Web3 from 'web3';
import $ from 'jquery'
const { ethereum } = window;
export default function Coin98(){
  
  const [walletAddress, setWalletAddress] = useState("");
   useEffect(()=>{
    getCurrentWalletConnected();
   });
  
    const ethEnabled = async () => {
      if(window.coin98){
        window.coin98.provider.request({ method: 'eth_requestAccounts' })
      .then((accounts) => {
                window.web3 = new Web3(window.coin98);
                console.log(accounts);
                window.$('#connectWallet').modal('hide');
               // $('#connectCoin98').modal().hide();
              //  $("#walletAddress").html(accounts[0].substring(0,5)+"..."+accounts[0].substring(38));
                if($("#WalletButton").length)
                 
                //$("#WalletButton").html('<a href="connect-wallet.php" class="default-btn move-right"><span>Connected</span> </a>');
                $("#WalletButton").html('<div class="nav-link  pd-top-25 walletAddress">'+accounts[0].substring(0,5)+"..."+accounts[0].substring(38)+'</div>');
                window.localStorage.setItem("connectionProvider", "coin98");
        window.localStorage.setItem("connectedWalletddress", accounts[0]); 
            
                  }).catch((error) => {
                            console.log("promise catches error! ");
                            console.error(error)
                            //alert(error)
                               });
         return true;
      }
      else
      {
            console.log("Please install coin98");
          const Swal2 = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success'
      },
      buttonsStyling: false
    });
    
     Swal2.fire({
            icon: 'warning',
            title: 'Coin98 wallet not found',
            text: 'Please install Coin98!',
            html: '<a href="https://chromewebstore.google.com/detail/coin98-wallet/aeachknmefphepccionboohckonoeemg?hl=en" target="_blank" style="color: #00F; ">Click here to download Coin98</a> ',
            confirmButtonText: 'Got it !'
        }); 
      }
      return false;
    };
    const disconnect = async () => { await window.coin98.provider.request({
            method: "wallet_requestPermissions",
            params: [{
                eth_accounts: {}
            }]
        }).then((result)=>{ ethEnabled(); }); };
    
    function connectCoin98(){
      if (window.coin98){  
        window.coin98.provider.request({ method: 'eth_chainId' })
                            .then((chain_id) => {
                                         console.log(chain_id);
                                         //alert(chain_id);
                                             if(chain_id == 0x1 || chain_id == 56)
                                          {
                                                    ethEnabled();
                                              }
                                            else
                                            {
                                             // Swal.fire("This app currently support only Mainnet or Rinkeby Testnet ! Please change network");      
                                            alert('This app currently support only Ethereum and Binance Smart Chain ! Please change network');
                                            }   
      
                                        }).catch((error) => {
                                                    console.log("promise catches error! ");
                                                    console.error(error)
                                                    });
                                            } 
        else
        {
          console.log("Please install coin98");
          //alert('Please install Coin98 to your web browser');
        const Swal2 = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success'
    },
    buttonsStyling: false
  });
  
   Swal2.fire({
          icon: 'warning',
          title: 'Coin98 wallet not found',
          text: 'Please install Coin98!',
          html: '<a href="https://chromewebstore.google.com/detail/coin98-wallet/aeachknmefphepccionboohckonoeemg?hl=en" target="_blank" style="color: #00F; ">Click here to download Coin98</a> ',
          confirmButtonText: 'Got it !'
      }); 
        }                                                                                 
    
    }

    

    const getCurrentWalletConnected = async() => {
      if(typeof window != "undefined" && typeof window.ethereum !="undefined" ){
       try {
         // Metamask installed 
         const accounts = await window.coin98.provider.request({ method: "eth_accounts"});
         if(accounts.length >0){
         console.log(accounts[0]);
         //alert("Your Metamask Wallet Connected Succesfully...")
        //document.getElementById('connectWallet').style.display = "none";
        //document.getElementsByClassName('modal-backdrop').style.display = "none";
        //document.getElementById('WalletButton').style.display = "none";
        $("#WalletButton").html('<div class="nav-link  pd-top-25 walletAddress">'+accounts[0].substring(0,5)+"..."+accounts[0].substring(38)+'</div>');
                
      //  window.location.reload();
        setWalletAddress(accounts[0]);
         } else{
          console.log("Connect Metamask using Connect button...");
         }
         
          }catch(err){
               console.error(err.message);
          }
      }else{
        
        console.log("Please install metamask ");
      }
   }
   

    return(  
        <>
        
        <a href="#" onClick={connectCoin98}><img src="assets/img/coin98.png" /> Coin 98</a>
        </>  
    )
}