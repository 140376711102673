import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Suspense, useEffect, useState } from 'react'
import Web3 from 'web3'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { CircularProgress , LinearProgress } from '@mui/material';
import Nav from '../components/Nav'
import FooterNav from '../components/FooterNav'
import Balance from '../components/getBalance'
import * as $AB from 'jquery'
import ComboBox from '../components/tokenlistCombobox'
import TokenList from '../availableTokenList.json'
import Chains from '../nativeCurrencyList.json'
import AppToken from '../appTokenDetails.json'
import ERC20 from '../erc20abi.json'
import IAGBNBPool from '../IAGBNBabi.json'
import Swal from 'sweetalert2';
import { useContext } from 'react';
import AppContext from '../components/AppContext';

function Swap() {
	
	console.log(window.localStorage.getItem('selectedChain'));
	const myContext = useContext(AppContext);
	var chainindex = window.localStorage.getItem('selectedChain')?window.localStorage.getItem('selectedChain'):0;
	var tokens = Object.values(TokenList[chainindex]['tokens']);
	var options = [];
	options = tokens?.map((e,i) => {
		 return( {"label":tokens[i].name, "symbol":tokens[i].symbol, "value": i});
	})
	console.log("Options:---> ")
	console.log(options);
	
	var walletadd = window.localStorage.getItem('connectedWalletddress')? window.localStorage.getItem('connectedWalletddress'):null;
	var web3 = new Web3(Web3.givenProvider);
	console.log(web3);
	console.log("chain stored: " + window.localStorage.getItem('selectedChain'));
	console.log("Token Two :");
	console.log(tokens[0]);
	const [isMarketPriceLoading, setIsMarketPriceLoading] = useState(false);
	const[isSwapping,setIsSwapping] = useState(false);
	const[isSwapped,setIsSwapped] = useState(false);
  const [connectedWallet, setCollectedWallet] = useState(walletadd);	
  const [tokenOneAmount, setTokenOneAmount] = useState(0.00);
  const [tokenTwoAmount, setTokenTwoAmount] = useState(0.00);
  const [bestQuoteTokenTwoAmount, setBestQuoteTokenTwoAmount] = useState(0.00);
  const [quotereq, setQuotereq]  = useState(false);
  const [esttokenTwoAmount, setEstTokenTwoAmount] = useState(0.00); 
  const [tokenOne, setTokenOne] = useState(TokenList[chainindex]['nativeCurrency']['0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee']);
  const [tokenTwo, setTokenTwo] = useState(tokens[0]);
  const [tokenOnePrice, setTokenOnePrice] = useState("0.00");
  const [tokenTwoPrice, setTokenTwoPrice] = useState("0.00");	
  const [tokenOneType, setTokenOneType] = useState("nativeCurrency");
  const [tokenTwoType, setTokenTwoType] = useState("tokens");
  const [chainIndex,setChainIndex]= useState(chainindex); //0: Ethereum, 1: BSC, 2: Polygon, 3: BSC Testnet
  const [changeToken, setChangeToken] = useState(1);
  const [showSelectTokenTwoButton, setshowSelectTokenTwoButton] = useState(true);
  const [prices, setPrices] = useState(null);
  const [isOpen, setIsOpen] = useState(false);	
  const handleNavClick = () =>
  {
	if(chainIndex != window.localStorage.getItem('selectedChain'))
	setChainIndex(window.localStorage.getItem('selectedChain'));
	console.log("Handle Nav Click execution .... In swap page-> current chain "+ Chains[chainIndex].Id);
  }
   useEffect(()=>{
	setTimeout(() => {console.log("Useffect execution!......");
	handleNavClick();
   	},1000
	)
   })
   let tokenBselect; 
  if(showSelectTokenTwoButton)
  {
    tokenBselect = <Button variant="outline-secondary" id="button-addon1" data-bs-toggle="modal" data-bs-target="#searchToken" className="select-token tokenB" onClick={() => openModal(2)}>
	<div className="selTokenText">
		  Select a token
   </div>
</Button>
  }
  else
  {
	console.log("selecting second token : ==> ");
	console.log(tokenTwo);
	tokenBselect = <Button variant="outline-secondary" id="button-addon1 tokenB" data-bs-toggle="modal" data-bs-target="#searchToken" className="select-token tokenB" onClick={() => openModal(2)}>
          				<div class="selTokenText">
						  <img src={tokenTwo.logoURI} /> {tokenTwo.symbol}
			 			</div>
        			</Button>
  }

  function changeAmount(val){
	
	console.log("changeAmount called :" + val);
	setTokenOneAmount(val);
	console.log(tokenOneAmount);
		setTokenTwoAmount(tokenOneAmount * prices);
		setEstTokenTwoAmount(tokenOneAmount * prices);
		setQuotereq(false);
		setBestQuoteTokenTwoAmount(0.00);
		console.log(val * prices);

	
  }
  async function switchTokens() {
    setPrices(0.00);
    setTokenOneAmount(0.00);
    setTokenTwoAmount(0.00);
	setEstTokenTwoAmount(0.00);
	setBestQuoteTokenTwoAmount(0.00);
	setQuotereq(false)
    const one = tokenOne;
    const two = tokenTwo;
    setTokenOne(two);
    setTokenTwo(one);
	console.log("after switiching token two => ");
	console.log(tokenTwo);
	const typeOne = tokenOneType;
	setTokenOneType(tokenTwoType);
	setTokenTwoType(typeOne);
    await fetchPrices(two, one);
  }
const handleChange = (e,i) =>{
	
	console.log("handleChange event ==> " + e);
	console.log(e);
	console.log("handleChange param==> " + i);
	console.log(i);
	modifyToken(i);
	
	
}
  async function modifyToken(i){
    setPrices(0.00);
	//console.log("Modify method called for =>" + TokenList[chainIndex]['tokens'][i].symbol);
    setTokenOneAmount(0.00);
    setTokenTwoAmount(0.00);
	setEstTokenTwoAmount(0.00);
	setBestQuoteTokenTwoAmount(0.00);
	setQuotereq(false);
	window.$('#searchToken').modal('hide');
	
    if (changeToken === 1) {
		
		if(i == 9999)
		{
			setTokenOne(TokenList[chainIndex]['nativeCurrency']['0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee']);
			setTokenOneType("nativeCurrency");
			await fetchPrices(TokenList[chainIndex]['nativeCurrency']['0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'], tokenTwo)
		}
		else
		{
		setTokenOne(tokens[i]);
		setTokenOneType("tokens");
        await fetchPrices(tokens[i], tokenTwo)
		}
    } else {
		if(i == 9999)
		{
			setTokenTwo(TokenList[chainIndex]['nativeCurrency']['0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee']);
			setTokenTwoType('nativeCurrency');
			await fetchPrices(tokenOne, TokenList[chainIndex]['nativeCurrency']['0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'])
		}
		else
		{
      setTokenTwo(tokens[i]);
	  setTokenTwoType("tokens");
      await fetchPrices(tokenOne, tokens[i])
		}
    }
     
  }
  async function fetchPrices(token1,token2){
	setIsMarketPriceLoading(true);	
 	console.log("Sending price request");

	if(Chains[chainIndex].type == "Mainnet" &&  token1.symbol != AppToken[chainIndex].symbol && token2.symbol != AppToken[chainIndex].symbol && token1.address != AppToken[chainIndex].address && token2.address != AppToken[chainIndex].address)
	{
	if(token2.symbol)
	{
	$AB.post(myContext.apiLocation + "/backend/app/config.php",
	{
		asset: token1.symbol 
	},
	function(data,status){
		var jdata = JSON.parse(data);
		if(jdata.status == 'ok')
		 {
		 console.log("asset A price in USD:" + jdata.assetprice);
		 setTokenOnePrice(jdata.assetprice);
		 $AB.post(myContext.apiLocation + "/backend/app/config.php",
	{
		asset: token2.symbol 
	},function(res,status)
	{
		var jres = JSON.parse(res);
		if(jres.status == 'ok')
		{
			console.log("asset B price in USD:" + jres.assetprice);
			setIsMarketPriceLoading(false);
			setTokenTwoPrice(jres.assetprice);
			setPrices(jdata.assetprice / jres.assetprice);
		}
		else
		console.log("Token2 Price fetch error!!! " + jres.status);
	});

	    
		 }	
		 else
		console.log("Token1 Price fetch error!!! " + jdata.status);	

		
		}
	);
	}
   }
   else
   {
	setIsMarketPriceLoading(false);
	setTokenOnePrice(null);
	setTokenTwoPrice(null);
   }
  }
  function openModal(asset) {
	if(asset == 2)
	 setshowSelectTokenTwoButton(false);
	setChangeToken(asset);
	setIsOpen(true);
  }
  function getBestQuote()
  {
	//Swal.fire("to Chain: "+ Chains[chainIndex].Id +" Sending --> " + 1 + " TokenOne : " + tokenOne.address + " TokenTwo: " + tokenTwo.address + " Amount : "+ tokenOneAmount);
	if(tokenOneAmount > 0)
    {
		if(Chains[chainIndex].type == "Mainnet" &&  tokenOne.symbol != AppToken[chainIndex].symbol && tokenTwo.symbol != AppToken[chainIndex].symbol && tokenOne.address != AppToken[chainIndex].address && tokenTwo.address != AppToken[chainIndex].address)
		{		
	$AB.post(myContext.apiLocation + "/backend/app/sendreqtoapi.php",
	  {
		flag: 1,
		source: tokenOne.address,
		sourcedecimals: tokenOne.decimals,
		dst: tokenTwo.address,
		amount: tokenOneAmount,
		chainid: Chains[chainIndex].Id
	  },
	  function(data,status)
	  {
		console.log(status);
		console.log(data);
			if(status == "success")
			{
				var jdata = JSON.parse(data);
				console.log(jdata.data);
				if(jdata.success)
				{
					var bestquote = jdata.quote/(10**(tokenTwo.decimals));
					console.log(jdata.quote/(10**(tokenTwo.decimals)));
					setBestQuoteTokenTwoAmount(bestquote);
					setTokenTwoAmount(bestquote);
					setQuotereq(true);
				}
				else{
					console.log(jdata.error);
				}
			}
	  }
	);
	}
	else
	{
		//Get quote for IAG (App token)
		var web3 = new Web3(Web3.givenProvider);
		if(AppToken[chainIndex].exists)
		{
		var poolContract = new web3.eth.Contract(IAGBNBPool,AppToken[chainIndex].tokenNativePool);
		let poolcurrencybalance = 0;
		let pooltokenbalance = 0;
		poolContract.methods.currencyPoolBalance().call({from: walletadd},function(error,result){
		poolcurrencybalance = result;
		poolContract.methods.getTokenPoolBalance().call({from: walletadd},function(err,res){
		pooltokenbalance = res;
			let swappedamount;
			if(tokenOne.symbol == AppToken[chainIndex].symbol)
			{
				if(pooltokenbalance > 0)
				  {
					poolContract.methods.getCurrencyAmountAfterSwap(web3.utils.toBN(web3.utils.toWei(tokenOneAmount,"ether"))).call({from: walletadd},function(e1,r1){
						if(!e1)
						 {
						  swappedamount = web3.utils.fromWei(r1,'ether');
						  setBestQuoteTokenTwoAmount(swappedamount);
						  setTokenTwoAmount(swappedamount);
						  setQuotereq(true);
						 }
						else
						  {
							swappedamount = 0;
							console.log(e1);
						  }	
					});
				  }
				 else
				  {
					swappedamount = 0;
					Swal.fire("The Pool is inactive");
				  } 
			}
			else
			{
				if(tokenTwo.symbol == AppToken[chainIndex].symbol)
				 {
					if(poolcurrencybalance > 0)
					{
					poolContract.methods.getTokenAmountAfterSwap(web3.utils.toBN(web3.utils.toWei(tokenOneAmount,"ether"))).call({from: walletadd},function(e2,r2){
						if(!e2)
						  {
							swappedamount = web3.utils.fromWei(r2,'ether');;
							setBestQuoteTokenTwoAmount(swappedamount);
						  setTokenTwoAmount(swappedamount);
						  setQuotereq(true);
						}
						else
						  {
							swappedamount = 0;
							console.log(e2);
						
						 }
					});
				   }
				   else{
					swappedamount = 0;
					Swal.fire("The Pool is inactive");
				   }
				 }
				 else
				 {
					swappedamount = 0;
				 }
			}

		});
		});
		
		}
	}
   }
   else
   {
	Swal.fire("Amount of Source token is empty!");
   }
  }

  function resetSwap()
  {
	setIsSwapped(false);
  }

  let swapbtn;
  let tokenTwoAmountField;
  let swappedbutton;
  swappedbutton = <button className="btn btn-default swap-btn"  type="button" onClick={(e) => {resetSwap();}}> Successfully Swapped (Reset the app)</button>
  if(!quotereq)
   {
	
	swapbtn = <button className="btn btn-primary swap-btn"  type="button" onClick={(e) => {getBestQuote();}}> Get Best Swap Rate</button>
	tokenTwoAmountField = <Form.Control
	id = "tokenBvalue"
	placeholder="0.00"
	  aria-label="Example text with button addon"
	  aria-describedby="basic-addon1"
	  value={tokenTwoAmount}
	disabled = {true}
	
	
/>
   }
  else
  {
	swapbtn = <button className="btn btn-primary swap-btn"  type="button" onClick = {(e)=>{swapTokens();}}> Swap </button>
    tokenTwoAmountField = <Form.Control
	id = "tokenBvalue"
	placeholder="0.00"
	  aria-label="Example text with button addon"
	  aria-describedby="basic-addon1"
	  value={bestQuoteTokenTwoAmount}
	disabled = {true}
	
	
/>

  }
  console.log("quotereq status : =>")
    console.log(quotereq);
function swapTokens()
{

	checkAllowance().then(function(response){
		if(response.confirmed)
		{
			   console.log("allowance checked and returned:--> " + response.allowance);
               return response.allowance;
		}
		else
		{
			console.log("Allowance checking promise resolve error!!");
		}
	}).then((allowance)=>{
		if(allowance < (tokenOneAmount * (10 ** tokenOne.decimals)))
			   {
				 //Call approve function
				 if(Chains[chainIndex].type == "Mainnet" &&  tokenOne.symbol != AppToken[chainIndex].symbol && tokenTwo.symbol != AppToken[chainIndex].symbol && tokenOne.address != AppToken[chainIndex].address && tokenTwo.address != AppToken[chainIndex].address)
				{
				 console.log("Now allow/give approval to spend !!");
				 setTimeout(async () => {
					await getApprovalCalldata().then(function(result){
						if(result.confirmed)
						{
							 
							//console.log(result);
							return ({"calldata": result.calldata,"amount": result.approveamount});
						}
						else
						{
							Swal.fire("Not confirmed!!!");
						}
					 }).then((calldata)=>{
						    //Swal.fire("Signing .... ");
							
							//give allowance (call token contract's approve function)
							console.log("after approval calldata: ---> ");
							console.log(calldata);
							console.log(tokenOne?tokenOne.address:null);
							//var tokenContract = new web3.eth.Contract(ERC20,tokenOne?tokenOne.address:null);
							//console.log(tokenContract);
							//tokenContract.methods.approve(Chains[chainIndex]["1inchrouter"],calldata.amount).send({from: walletadd}).then(function(transactionObject) 
							//									{
							//										console.log(transactionObject);
							//									});
							let params;
							params = [{
								"from" : walletadd,
								"to" : calldata.calldata.to,
								"data": calldata.calldata.data,
								"gasPrice": Number(calldata.calldata.gasPrice).toString(16)

							}];
							window.ethereum.request({
								method: 'eth_sendTransaction',
								params: params
							}).then(response => {console.log(response)}).catch(addError => {
								console.log("error after trying to send approval transaction");
								console.error(addError);
							  });
							
					 }).catch(function(error){
						console.log(error);
						Swal.fire("Error occured !!!");
					 });
				 },1000);
				}
				else{
					console.log("Approval started...");
					console.log("Within the conditional block where any one token is the app token!!");
					//Swal.fire("Approval started!!...");
					if(tokenOne.symbol == AppToken[chainIndex].symbol)
					{
						//approve App tokens 
						console.log("approving..... Only if token one is the app token");
						var web3 = new Web3(Web3.givenProvider);
						console.log(web3);
						var AppTokenContract = new web3.eth.Contract(ERC20,tokenOne.address);
						console.log(AppTokenContract);
						console.log(tokenOneAmount);
						var approvedTokens = web3.utils.toBN(web3.utils.toWei((tokenOneAmount * 1000).toString(),"ether"));
						console.log(approvedTokens);
						AppTokenContract.methods.approve(AppToken[chainIndex].tokenNativePool,approvedTokens).send({from: walletadd}, function(txerror, tx){
							console.log("Sending web3 transaction calling approve method....");
							/*if(txerror)
                                    {
									  console.log(txerror);
									  Swal.fire(txerror.message);
									}*/
						    if(tx)
							  {
								console.log("Wait for the approval!! ....");
								var timerInterval;
								Swal.fire({
											html: '<h4>Approving IAG/BNB Pool to spend your IAG...</h4><br /><b></b>',
											allowOutsideClick: false,
											timer: 8000,
											timerProgressBar: true,
											didOpen: () => {
												Swal.showLoading();
												 const timer = Swal.getPopup().querySelector("b");
												timerInterval = setInterval(() => {
												timer.textContent = `${Swal.getTimerLeft()}`;
												 }, 100);
															},
															willClose: () => {
																		 clearInterval(timerInterval)

																		}                
											});
								setTimeout(() => {
												web3.eth.getTransactionReceipt(tx).then(async (receipt)=>{
													if(receipt.status)
													{
														Swal.fire({
																	icon: 'success',
																	html: '<h4>Approved!</h4>'
																}).then((confirmed)=> {
																	//window.location.reload(this);
																});
													}
												}).catch((error)=>{
													console.log(error);
												});
									 },10000);
							  }
						});
					}
					else
					{
						if(tokenTwo.symbol == AppToken[chainIndex].symbol)
						{
							//Swap
							setIsSwapping(true);
							console.log("Swap native currency to get App token");
							console.log(IAGBNBPool);
							console.log(chainIndex);
							console.log(AppToken[chainIndex].tokenNativePool);
							console.log(Web3.givenProvider);
							//new web3.eth.Contract(IAGBNBPool,AppToken[chainIndex].tokenNativePool).then((contr)=>{console.log(contr)});
							const web3 = new Web3(Web3.givenProvider);
							var appTokenNativeSwapContract = new web3.eth.Contract(IAGBNBPool,AppToken[chainIndex].tokenNativePool);
							console.log(appTokenNativeSwapContract);
							console.log(walletadd);
							
							appTokenNativeSwapContract.methods.swapCurrencytoToken().send({from: walletadd, value: web3.utils.toBN(web3.utils.toWei((tokenOneAmount).toString(),"ether"))})
							.on('transactionHash',function(hash){
								console.log(hash);
								var timerInterval;
								Swal.fire({
									html: loader + '<h4>Swapping '+ tokenOne.symbol+' to '+ tokenTwo.symbol+'..</h4><br /><b></b>',
									allowOutsideClick: false,
									timer: 8000,
									timerProgressBar: true,
									didOpen: () => {
										Swal.showLoading();
										 const timer = Swal.getPopup().querySelector("b");
										timerInterval = setInterval(() => {
										timer.textContent = `${Swal.getTimerLeft()}`;
										 }, 100);
													},
													willClose: () => {
																 clearInterval(timerInterval)

																}                
									});
							}).on('confirmation',function(confirmationNumber,receipt){

							}).on('receipt',function(receipt){
								console.log(receipt);
								if(receipt.status)
													{
														Swal.fire({
																	icon: 'success',
																	html: '<h4>Swapped!</h4>'
																}).then((confirmed)=> {
																	//window.location.reload(this);
																													   //Log transaction offchain
													//send data to server
													logTransaction(receipt.transactionHash,tokenOne.address,tokenTwo.address,connectedWallet,tokenOneAmount,bestQuoteTokenTwoAmount,Chains[chainIndex].Id);				
										 setIsSwapping(false);
										 
																});
													}
							}).on('error',function(error,receipt){
								console.log(error);
								Swal.fire({ text: error.message});
							});
						}
					}
				}

			   }
			   else
			   {
				 //Swap
				 console.log("Now swap !!");
				 setIsSwapping(true);
				 if(tokenOne.symbol != AppToken[chainIndex].symbol)
				 setTimeout(async () => {
					await getSwapCalldata().then(function(result){
						if(result.confirmed)
						{
							console.log(result);
							let params = [{
								"from" : result.calldata.tx.from,
								"to" : result.calldata.tx.to,
								"data": result.calldata.tx.data,
								"gas": Number(result.calldata.tx.gas).toString(16),
								"gasPrice": Number(result.calldata.tx.gasPrice).toString(16),
								"value": Number(result.calldata.tx.value).toString(16)

							}];
							console.log(params);
							let tokentwobalance = 0;
							if($AB("#resultant").html() != 0 && $AB("#resultant").html() != null)
							 tokentwobalance = $AB("#resultant").html();
							window.ethereum.request({
								method: 'eth_sendTransaction',
								params: params
							}).then(response => {
								console.log(response);
								setTimeout(async ()=>{
									await getTransactionDetails(response,1).then((receiptdata)=>
									{
										if(receiptdata.confirmed)
										{
											if(receiptdata.receipt.status)
											{
												 console.log(receiptdata);
												setIsSwapped(true);
												let currtokentwobalance = tokentwobalance;
												setTimeout(() => {
													console.log($AB("#resultant").html());
													currtokentwobalance = $AB("#resultant").html();
												console.log("Destination token current balance");
												console.log(currtokentwobalance);
												   let receivedAmount = currtokentwobalance - tokentwobalance;
													if(receivedAmount == 0)
													  getTransactionDetails(response,2)
													else
													{
												   //Log transaction offchain
													//send data to server
													logTransaction(response,tokenOne.address,tokenTwo.address,connectedWallet,tokenOneAmount,bestQuoteTokenTwoAmount,Chains[chainIndex].Id);
													setIsSwapping(false);
													Swal.fire("You received "+receivedAmount+" "+tokenTwo.symbol);
														}		
												},6000)
			
											}
											else
											{
												console.log("There's some error");
											}
										}
										else
										{
											console.log("Transaction not confirmed yet !!!");
										}
									}

									).catch((err)=> {console.error(err);
									                  Swal.fire(err);
									});
								},10000);
								
							  
							}).catch(addError => {
								console.log("error after trying to send swap transaction");
								console.error(addError);
							  });
						}
					}).then((calldata)=>{

					}).catch(function(error){

					});
				 },1000);
				 else{
					//swap in IAG/BNB Pool
					const web3 = new Web3(Web3.givenProvider);
							var appTokenNativeSwapContract = new web3.eth.Contract(IAGBNBPool,AppToken[chainIndex].tokenNativePool);
							console.log(appTokenNativeSwapContract);
							console.log(walletadd);
							
							appTokenNativeSwapContract.methods.swapTokenToCurrency(web3.utils.toBN(web3.utils.toWei((tokenOneAmount).toString(),"ether"))).send({from: walletadd})
							.on('transactionHash',function(hash){
								console.log(hash);
								var timerInterval;
								Swal.fire({
									html: loader + '<h4>Swapping '+ tokenOne.symbol+' to '+ tokenTwo.symbol+'..</h4><br /><b></b>',
									allowOutsideClick: false,
									timer: 8000,
									timerProgressBar: true,
									didOpen: () => {
										Swal.showLoading();
										 const timer = Swal.getPopup().querySelector("b");
										timerInterval = setInterval(() => {
										timer.textContent = `${Swal.getTimerLeft()}`;
										 }, 100);
													},
													willClose: () => {
																 clearInterval(timerInterval)

																}                
									});
							}).on('confirmation',function(confirmationNumber,receipt){

							}).on('receipt',function(receipt){
								console.log(receipt);
								if(receipt.status)
													{
														Swal.fire({
																	icon: 'success',
																	html: '<h4>Swapped!</h4>'
																}).then((confirmed)=> {
																	//window.location.reload(this);
																													   //Log transaction offchain
													//send data to server
													logTransaction(receipt.transactionHash,tokenOne.address,tokenTwo.address,connectedWallet,tokenOneAmount,bestQuoteTokenTwoAmount,Chains[chainIndex].Id);				
										 setIsSwapping(false);
										 
																});
													}
							}).on('error',function(error,receipt){
								console.log(error);
								Swal.fire({ text: error.message});
							});

				 }
			   }
	}).catch(function(err){
		Swal.fire(err);
	});


}
  async function getTransactionDetails(tx,counter)
  {
	const transactionReceiptAsync =  new Promise(function(resolve,reject)
	{
		console.log(counter);
	setTimeout(() => {	
		web3.eth.getTransactionReceipt(tx).then(async (receipt)=>{
			//console.log(receipt);
			if(receipt.status)
			{
			
				resolve({receipt: receipt, confirmed: true});			
			}
			else{
				  if(receipt == null)
				  {
					if(counter < 10)
					{
						//recursively call for the receipt
						counter++;
						await getTransactionDetails(tx,counter);
					}
					else
					{
						//reject
						reject("The transaction is yet not confirmed!! keep checking at block explorer");
					}
				 }
				 else{
					reject("Transaction logged but with error !!");
				 }
				
			}
		   
		}).catch((error)=>{
			 console.log(error);
			 console.error(error);
			 reject("Can not get Transaction Receipt");
		});
	  },10000);
	});
	return transactionReceiptAsync;
  }
  async function getSwapCalldata()
  {
	return new Promise(function(resolve,reject){
		$AB.post(myContext.apiLocation + "/backend/app/sendreqtoapi.php",
		   {
			flag: 4,
			source: tokenOne.address,
			dst: tokenTwo.address,
			from: connectedWallet,
			amount: tokenOneAmount,
			decimals: tokenOne.decimals,
			chainid: Chains[chainIndex].Id
		   },
		   function(data,status)
		   {
			console.log(status);
			console.log(data);
				if(status == "success")
				{
					var jdata = JSON.parse(data);
					console.log(jdata.data);
					
					if(jdata.success)
					{
						console.log(jdata.success);
						var calldata = jdata.calldata;
						resolve({calldata: calldata,approveamount: jdata.amount,confirmed: true});
					}
					else{
						console.log(jdata.error);
						reject(jdata.error);
					}
				}
				else
				{
					reject("Network error! ");
				}
		   }
		);
	 });
  }

  async function getApprovalCalldata()
  {
	 return new Promise(function(resolve,reject){
		$AB.post(myContext.apiLocation + "/backend/app/sendreqtoapi.php",
		   {
			flag: 3,
			source: tokenOne.address,
			amount: tokenOneAmount,
			decimals: tokenOne.decimals,
			chainid: Chains[chainIndex].Id
		   },
		   function(data,status)
		   {
			console.log(status);
			console.log(data);
				if(status == "success")
				{
					var jdata = JSON.parse(data);
					console.log(jdata.data);
					
					if(jdata.success)
					{
						console.log(jdata.success);
						var calldata = jdata.calldata;
						resolve({calldata: calldata,approveamount: jdata.approveamount,confirmed: true});
					}
					else{
						console.log(jdata.error);
						reject(jdata.error);
					}
				}
				else
				{
					reject("Network error! ");
				}
		   }
		);
	 });
  }
  async function checkAllowance()
  {
	return new Promise(function(resolve,reject){
	   if(connectedWallet)	
	   {
		if($AB("#swappable").html() != 0 && tokenOneAmount <= $AB("#swappable").html())
		{
		if(tokenOneAmount > 0)
		{
			if(Chains[chainIndex].type == "Mainnet" &&  tokenOne.symbol != AppToken[chainIndex].symbol && tokenTwo.symbol != AppToken[chainIndex].symbol && tokenOne.address != AppToken[chainIndex].address && tokenTwo.address != AppToken[chainIndex].address)
			{		
		$AB.post(myContext.apiLocation + "/backend/app/sendreqtoapi.php",
		  {
			flag: 2,
			source: tokenOne.address,
			wallet: connectedWallet,
			chainid: Chains[chainIndex].Id
		  },
		  function(data,status)
		  {
			console.log(status);
			console.log(data);
				if(status == "success")
				{
					var jdata = JSON.parse(data);
					console.log(jdata.data);
					if(jdata.success)
					{
						var allowance = jdata.allowance;
						resolve({allowance: allowance,confirmed: true});
					}
					else{
						console.log(jdata.error);
						reject(jdata.error);
					}
				}
				else
				{
					reject("Network error! ");
				}
		  }
		);
		}
		else
		{
			//check allowance for IAG->BNB 
			var web3 = new Web3(Web3.givenProvider);
			if(tokenOne.symbol == AppToken[chainIndex].symbol)
			{
				//check allowance of IAG //tokenOne.address
				var IAGContract = new web3.eth.Contract(ERC20,tokenOne.address);
				IAGContract.methods.allowance(walletadd,AppToken[chainIndex].tokenNativePool).call({from: walletadd},function(error,result){
					if(!error)
					{
						resolve({allowance: result, confirmed: true});
					}
					else
					{
						reject("Couldn't check allowance");
					}
				});

				
			}
			else{
				resolve({allowance: 100000,confirmed: true});
			}

		}
	   }
	   else
	   {
		reject("Amount of Source token is empty!");
	   }
	  }
	  else
	  {
		reject("You don't have enough balance!");
	  }
	 }
	  else
	    reject("You are not connected to any wallet");
	});

  }	

  function logTransaction(tx,t1,t2,txfrom,t1amount,t2amount,chainid)
  {
	$AB.post(myContext.apiLocation + "/backend/app/logtransactions.php",
	{	
	 flag: 1,
	 tx: tx,
	 t1token: t1,
	 t2token: t2,
	 txfrom: txfrom,
	 t1amount: t1amount,
	 t2amount: t2amount,
	 chain: chainid
 },function(data,status){
	 if(status == 'success')
	 {
		 var jdata = JSON.parse(data);
		 console.log(jdata);
		 if(jdata.success)
		 {
			 console.log(jdata.msg);
			 if(jdata.receivereward)
			   Swal.fire("Claim your reward : "+ jdata.receivereward + "  IAG");
		 }
		 else
		  {
			 console.log(jdata.error);
		  }
	 }
 });
  }

  let loader;
  let linearloader;
  loader = <CircularProgress fourcolor variant="indeterminate" />
  linearloader = <LinearProgress />
  useEffect(()=>{
	
    setTokenOne(TokenList[chainIndex]["nativeCurrency"]['0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee']);
	setTokenTwo(tokens[0]);
	fetchPrices(TokenList[chainIndex]["nativeCurrency"]['0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'],tokens[0])
	setQuotereq(false)
	setEstTokenTwoAmount(0.00)
  }, [chainIndex])

  useEffect(()=>{
	
    fetchPrices(tokenOne, tokenTwo)
	setQuotereq(false)
	setEstTokenTwoAmount(0.00)

  }, [])



  
  return (
    <React.Fragment>
        <Nav onClick={handleNavClick} onChange={handleNavClick}/>

<div className="home-about swap">
<div className="container">
   <div className="row">
   
    <div className="col-md-6" style={{margin:'0 auto'}}>
     <div className="swap-body">
	    <h1>Swap</h1>
        <div className="row">
		  <div className="swap-inner">

		    <div className="col-md-12">
		    
			<h6>From:</h6>
			</div>
			<div className="row clearfix">
			<InputGroup className="mb-3 swapfield">
        			<Button variant="outline-secondary" id="button-addon2 tokenA" data-bs-toggle="modal" data-bs-target="#searchToken" className="select-token tokenA"  onClick={(e) => openModal(1)}>
          				
			 			<img src={tokenOne.logoURI} /> {tokenOne.symbol}
			 		
        			</Button>
        			<Form.Control
						controlId="tokenOneAmountId"
        				placeholder="0.00"
          				aria-label="Example text with button addon"
          				aria-describedby="basic-addon1"
          				value = {tokenOneAmount}
						onChange = {(e) => changeAmount(e.target.value)}
        			/>
      			</InputGroup>
			
			<div className="col-md-6">
			<h6>Balance: <Balance wallet={connectedWallet} contract={tokenOne?tokenOne.address:null} type={tokenOneType} field="swappable"/></h6>
			</div>
			<div className="col-md-6">
			<h6 style={{textAlign:'right',color:'#999'}}><span style={{color: "#666",fontSize: "9px"}}>Market Price: </span>{isMarketPriceLoading? loader : ((tokenOnePrice) ? "$"+tokenOnePrice : "N/A")}</h6>
			</div>
			</div>
			</div>
			
			<div className="row clearfix">
			<div className="col-md-12 text-center equal switchButton" >
			<img src="assets/img/equal.png" onClick={switchTokens}/>
			</div>
			</div>
			
			<div className="swap-inner">
				<div className="col-md-12">
					
				<h6>To:</h6>
			</div>
			<div className="row clearfix">
			<InputGroup className="mb-3 swapfield">
        			{tokenBselect}
        			{tokenTwoAmountField}
      			</InputGroup>
			
			<div className="col-md-6">
			<h6>Balance: <Balance wallet={connectedWallet} contract={tokenTwo?tokenTwo.address:null} type={tokenTwoType} field="resultant"/></h6>
			</div>
			<div className="col-md-6">
			<h6 style={{textAlign:'right',color:"#999"}}><span style={{color: "#666",fontSize: "9px"}}>Market Price: </span> {isMarketPriceLoading? loader : ((tokenTwoPrice) ? "$"+tokenTwoPrice : "N/A")} <br /><span style={{color: "#666",fontSize: "9px"}}> Estimated token amount on market price: </span>{esttokenTwoAmount}</h6>
			</div>
			<div className="col-md-12">
			<h6 style={{textAlign:'center',color:"#ccc"}}><span style={{color: "#aaa",fontSize: "12px"}}>Best quote (Amount of tokens you receive after swap): </span> {bestQuoteTokenTwoAmount}</h6>
			</div>

				</div>
			</div>

			
			 
			   {isSwapping ? linearloader : (isSwapped ? swappedbutton : swapbtn)}
			
			
		  </div>
		
		</div>

     </div>
    </div>   
  
</div>
</div>



<FooterNav />


<div className="modal" id="searchToken" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="staticBackdropLabel" style={{color:'#ccc'}}>Select a token</h1>
        <button type="button" id="closebtn" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <div className="row">
		  <div className="col-md-12">
			<ComboBox chain={ chainIndex } token={tokens} onChange={(e,i) => {handleChange(e,i)}}/>
		    
		   </div>
		   <div className="col-md-3">
		    <div className="tokenList">
		    <Link to="#" onClick={(e) => modifyToken(9999)} ><img src={TokenList[chainIndex]['nativeCurrency']['0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'].logoURI} style={{maxWidth: '30px'}} /> {TokenList[chainIndex]['nativeCurrency']['0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'].symbol} </Link>
			</div>
		   </div>
		   {
			tokens.slice(0,11)?.map((e,i) => {

				return(
					<div className="col-md-3">
					<div className="tokenList">
					<Link to="#" onClick={(e) => modifyToken(i)}><img src={tokens[i].logoURI} style={{maxWidth: '30px'}} /> {tokens[i].symbol}</Link>
					</div>
				   </div>	
				);
			})
		   }
		   
		   <br />
					<p style={{ color: "#fff"}}> ........</p>
		   
		</div>
      </div>
   
    </div>
  </div>
</div>
    </React.Fragment>
  )
}
export default Swap