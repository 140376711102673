import React from 'react'
import { Link } from 'react-router-dom'
import Nav from '../components/Nav'
import FooterNav from '../components/FooterNav'
export default function HowItWorks() {
  return (
    <React.Fragment>
       <Nav />

<div className="how-it-works">
<div className="container">
   <div className="row">
   <div className="col-md-12 ">
   <h1>How The Exchange Works </h1>
   <p>IAG has been developed to enhance users’ experiences within the decentralized environment. You can exchange or trade your coins and tokens here in three easy steps:</p>
   </div>
   <div className="col-md-12">
   <h2>1. Connect Wallet</h2>    
   <p>IAG offers connection with all the leading wallets, like Metamask, Trust wallet, Math wallet, etc. You can connect to eight leading wallets as of now, and more options will be available in the future.</p>
   </div>
   
   <div className="col-md-12">
   <h2>2. Select Token Pair</h2>    
   <p>IAG offers all the token pairs available on Ethereum and Binance. Select the token pair you want to trade and off you go.</p>
   </div>
   
   <div className="col-md-12">
   <h2>3. Swap & Earn</h2>    
   <p>After selecting the token pair, you just execute the trade, and the transaction is completed. For each transaction you do on the exchange, you will get valuable IAG tokens as rewards. This promotion is available for the first three months after launch.</p>
   </div>
	 
	
	
</div>
</div>
</div>


<FooterNav />
    </React.Fragment>
  )
}
