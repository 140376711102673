import React from 'react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import Web3 from 'web3'
import $ from 'jquery'
import Chains from '../nativeCurrencyList.json'

const { ethereum } = window;

let provider = (window.ethereum?.isMetaMask && typeof window.ethereum !="undefined")?window.ethereum : null;
export default function Metamask(props){
  const [chainIndex,setChainIndex]= useState(window.localStorage.getItem('selectedChain')?window.localStorage.getItem('selectedChain'):0);
  const [walletAddress, setWalletAddress] = useState("");
   useEffect(()=>{
    getCurrentWalletConnected();
   });
  if(provider?.isMetaMask)
  {
   provider.on('chainChanged', (chainId) => {
    checkAndHandleChains()
    //window.location.reload()
  
  });
}

    const ethEnabled = async () => {
      if (provider?.isMetaMask){
      provider.request({ method: 'eth_requestAccounts' })
      .then((accounts) => {
                window.web3 = new Web3(provider);
                console.log(accounts);
                window.$('#connectWallet').modal('hide');
                if($("#WalletButton").length)
                 
                //$("#WalletButton").html('<a href="connect-wallet.php" class="default-btn move-right"><span>Connected</span> </a>');
                $("#WalletButton").html('<div class="nav-link  pd-top-25 walletAddress">'+accounts[0].substring(0,5)+"..."+accounts[0].substring(38)+'</div>');
                //$("#connectWalletModalBody").html('<div className="row"><div className="col-md-12"><div className="walletList"><a href="#" onlick="requestPermissions();">Connect with New wallet address</a> </div></div><div className="col-md-12"><div className="walletList"> <a href="#" >Disconnect</a></div></div></div>');
                window.localStorage.setItem("connectionProvider", "metamask");
                window.localStorage.setItem("connectedWalletddress", accounts[0]);
                Swal.fire(
                  {
               title: "You are now connected with",
               text: accounts[0],
               allowOutsideClick: false,
               icon: "success"
                  }
               ).then((res)=>{if(res){
                 window.location.reload(this);
             }});
                
                  }).catch((error) => {
                            console.log("promise catches error! ");
                            console.error(error)
                            //alert(error);
                            
                               });
         return true;
      }
      else
      {
            console.log("Please install metamask");
            alert('Please install Metamask to your web browser');
         const Swal2 = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success'
      },
      buttonsStyling: false
    });
    
     Swal2.fire({
            icon: 'warning',
            title: 'No wallet found',
            text: 'Please install Metamask!',
            html: '<a href="https://metamask.io/download.html" target="_blank" style="color: #00F; ">Click here to download Metamask</a> ',
            confirmButtonText: 'Got it !'
        }); 
      }
      return false;
    };

    function requestPermissions() {
      if(typeof provider !="undefined" )
      {
      provider.request({
          method: 'wallet_requestPermissions',
          params: [{ eth_accounts: {} }],
        })
        .then((permissions) => {
          const accountsPermission = permissions.find(
            (permission) => permission.parentCapability === 'eth_accounts'
          );
          if (accountsPermission) {
            console.log('eth_accounts permission successfully requested!');
          }
        })
        .catch((error) => {
          if (error.code === 4001) {
            // EIP-1193 userRejectedRequest error
            console.log('Permissions needed to continue.');
          } else {
            console.error(error);
          }
        });
      }
    }
    
   function disconnect() {
    provider.request({
      "method" : "wallet_revokePermissions",
      "params": [
            {
              "eth_accounts" : {}
            }
      ]
   }).then((res) => {
     if(!res)
     {
      window.localStorage.removeItem("connectionProvider");
      window.localStorage.removeItem("connectedWalletddress");
        window.$("#connectWallet").modal('hide');
        Swal.fire(
             {
          title: "All Metamask wallets are disconnected",
          text: "Disconnected",
          allowOutsideClick: false,
          icon: "warning"
             }
          ).then((res)=>{if(res){
            window.location.reload(true);
        }});
     }  
        
   })}

function checkAndHandleChains(){
  provider.request({ method: 'eth_chainId' })
  .then((chain_id) => {
               console.log(chain_id);
               //alert(chain_id);
                   if(chain_id == Chains[chainIndex].Id)
                {
                          ethEnabled();
                    }
                  else
                  {
                    
                    let walletnetworkname =" a different ";
                    for(var k=0;k < Chains.length; k++){
                        if(Chains[k].Id == chain_id)
                        {
                            walletnetworkname = Chains[k].title;
                            break;
                        }
                    }
                    Swal.fire(
                      {
                   title: "You Selected "+Chains[chainIndex].Name+" Chain in application " + "But wallet is connected to "+ walletnetworkname +"Chain",
                   text: "Please change network in your wallet",
                   allowOutsideClick: false,
                   showDenyButton: true,
                   confirmButtonText: "Change Network of wallet to "+ Chains[chainIndex].Name,
                   denyButtonText: `Contunue with your Wallet's Current Network`,
                   icon: "warning"
                      }
                   ).then((res)=>{if(res.isConfirmed){
                     
                      // check if the chain to connect to is installed
                      provider.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: Chains[chainIndex].chainid }], // chainId must be in hexadecimal numbers
                      }).then(response => {console.log(response);}).catch (error => {
                        console.log("error after trying network switching => ");
                        console.error(error);
                        if (error.code === 4902) {
                            var rpcurlarr = new Array([Chains[chainIndex].rpcURL]);
                            console.log(Chains[chainIndex].chainid);
                            console.log("rpc url array :-->");
                            console.log(rpcurlarr);
                            console.log(Chains[chainIndex].title);
                            console.log(Chains[chainIndex].Currency);
                            console.log(Chains[chainIndex].Currency);
                            
                            provider.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                              {
                                chainId: Chains[chainIndex].chainid,
                                rpcUrls: rpcurlarr,
                                chainName: Chains[chainIndex].title,
                                nativeCurrency: {
                                   name: Chains[chainIndex].Currency,
                                   symbol: Chains[chainIndex].Currency,
                                   decimals: 18
                                },
                                blockExplorerUrls: [Chains[chainIndex].explorerLink]
                              }
                            ]
                          }).then((response) => {console.log(response)}).catch (addError => {
                            console.log("error after trying to add network");
                            console.log(addError.data.originalError);
                            console.error(addError);
                          }) 
                      }
                      }) 
                    

                   }
                   else if(res.isDenied)
                   {
                    let chainindex;
                    var casefound= false;
                    for(var k=0;k < Chains.length; k++){
                      if(Chains[k].Id == chain_id)
                      {
                          chainindex =k;
                          casefound = true;
                          break;
                      }
                      else{
                        chainindex = chainIndex;
                      casefound = false;
                      Swal.fire("This app currently support only Ethereum Mainnet or Binance Smart Chain or Polygon Mainnet! Please change network").then((confirmation) => {
                        if(confirmation.isConfirmed)
                        {
                          provider.request({
                            method: 'wallet_switchEthereumChain',
                            params: [{ chainId: Chains[chainindex].chainid }], // chainId must be in hexadecimal numbers
                          }).then((response) => {response.json(); window.location.reload(this);}).catch(error => {console.log(error)})
                        }
                      });  
                      }
                  }
                 
                    
                    window.localStorage.setItem('selectedChain',chainindex);
                    if(casefound)
                     window.location.reload(this);
                    
                   }
                  });      
                   /*alert('This app currently support only Ethereum and Binance Smart Chain ! Please change network');*/
                   /* ---- Change Network ---- */
                   
                  }   

              }).catch((error) => {
                          console.log("promise catches error! ");
                          console.error(error)
                          });
}

    function connectWallet(){
      if(window.ethereum?.isTokenPocket)
 {
  window.ethereum.isTokenPocket = false;
  console.log(window.ethereum.isTokenPocket);
 } 
 if(typeof window.ethereum !="undefined" && !window.ethereum?.isMetaMask)
 {
  window.ethereum.isMetaMask =true;
  console.log(window.ethereum.isMetaMask);
  provider = window.ethereum;
 }
      console.log("check the window =>");
      console.log(window);
      console.log("provider");
      //console.log(ethereum);
       
      if (provider?.isMetaMask){
                                  checkAndHandleChains();
                                 }
                                 else{
                                  const Swal2 = Swal.mixin({
                                    customClass: {
                                      confirmButton: 'btn btn-success'
                                    },
                                    buttonsStyling: false
                                  });
                                  
                                   Swal2.fire({
                                          icon: 'warning',
                                          title: 'No wallet found',
                                          text: 'Please install Metamask!',
                                          html: '<a href="https://metamask.io/download.html" target="_blank" style="color: #00F; ">Click here to download Metamask</a> ',
                                          confirmButtonText: 'Got it !'
                                      }); 
                                 }
                                                                                              
    
    }
   
    const getCurrentWalletConnected = async() => {
      console.log("check provider.request");
      console.log(provider.request);
      if(typeof window != "undefined" && typeof provider !="undefined" ){
       
       try {
         // Metamask installed 
         const accounts = await provider.request({ method: "eth_accounts"});
         if(accounts.length >0){
         console.log(accounts[0]);
        $("#WalletButton").html('<div class="nav-link  pd-top-25 walletAddress">'+accounts[0].substring(0,5)+"..."+accounts[0].substring(38)+'</div>');
        window.localStorage.setItem("connectionProvider", "metamask");
        window.localStorage.setItem("connectedWalletddress", accounts[0]);       
      //  window.location.reload();
        setWalletAddress(accounts[0]);
         } else{
          console.log("Connect Metamask using Connect button...");
         }
         
          }catch(err){
               console.log(err);
          }
      }else{
        
        console.log("Please install metamask ");
      }
   }
   let walletLink;
   let disconnectbtn;
   if(props.status == "connected")
   {
    walletLink = <Link to="#" onClick={(event) => {connectWallet()}}><img src="assets/img/meta.png" /> Metamask <span style={{color: '#0f0',fontSize: '12px'}}>connected</span></Link>
    disconnectbtn = <a href="#" onClick= {(event) =>{ disconnect()}} style={{ float: "right"}}> Disconnect </a>
   }
   else
   {
    walletLink = <Link to="#" onClick={(event) => {connectWallet()}}><img src="assets/img/meta.png" /> Metamask</Link>
    
  }

    return(  
        <>
        
        
        { walletLink } { disconnectbtn }
        </>  
    )
}
