import React from 'react'
import { Link } from 'react-router-dom'
import Nav from '../components/Nav'
import FooterNav from '../components/FooterNav'
export default function Contact() {
  return (
    <React.Fragment>
        <Nav />


<div className="home-about faq">
<div className="container">
   <div className="row">
   <h1>CONTACT US</h1>
   <div className="col-md-2"></div>
   <div className="col-md-8 ">
   <form method="POST" action="mail.php">
   <div className="row">
   <div className="col-md-6 p-4">
  <div className="form-group " style={{textAlign:'left'}}>
    <label for="exampleInputEmail1">Name*:</label>
    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name="txt_name"  placeholder="Your Name" required />
    
  </div>
  </div>
  <div className="col-md-6 p-4">
  <div className="form-group" style={{textAlign:'left'}}>
    <label for="exampleInputEmail1">Email address*:</label>
    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name="txt_mail"placeholder="Enter email" required />
  </div>
  </div>
  <div className="col-md-12 p-4">
  <div className="form-group" style={{textAlign:'left'}}>
    <label for="exampleInputEmail1">Phone Number:</label>
    <input type="phone" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name="txt_ph" placeholder="Phone number" />
    
  </div>
  </div>
  <div className="col-md-12 p-4">
    <div className="form-group" style={{textAlign:'left'}}>
    <label for="exampleFormControlTextarea1" >Message*:</label>
    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" name="txt_msg" required placeholder="Message...."></textarea>
  </div>
  </div>
  <div className="col-md-12 p-4 ">
  <button type="submit" className="btn btn-primary  p-2" style={{width:'100%'}}>SEND MESSAGE</button>
  </div>
  </div>
</form>
   
   </div>
   
   
  
</div>
</div>
</div>


<FooterNav />
    </React.Fragment>
  )
}
