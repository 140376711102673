import React from 'react'
import { useEffect, useState } from 'react'
//import swal from 'sweetalert';
import Swal from 'sweetalert2';
import Web3 from 'web3';
import $ from 'jquery';

const { BinanceChain } = window;

export default function BinanceWallet(){
  
  const [walletAddress, setWalletAddress] = useState("");
  useEffect(()=>{
   //getCurrentWalletConnected();
  });
 
   const ethEnabled = async () => {
    
     if (window.BinanceChain){
     window.BinanceChain.request({ method: 'eth_requestAccounts' })
     .then((accounts) => {
               window.web3 = new Web3(window.BinanceChain);
               console.log(accounts);
               window.$('#connectWallet').modal('hide');
               //$('#connectWallet').modal('hide')
              // $('#connectWallet').modal().hide();
             //  $("#walletAddress").html(accounts[0].substring(0,5)+"..."+accounts[0].substring(38));
               if($("#WalletButton").length)
                
               //$("#WalletButton").html('<a href="connect-wallet.php" class="default-btn move-right"><span>Connected</span> </a>');
               $("#WalletButton").html('<div class="nav-link  pd-top-25 walletAddress">'+accounts[0].substring(0,5)+"..."+accounts[0].substring(38)+'</div>');
               
               window.localStorage.setItem("connectionProvider", "binance");
        window.localStorage.setItem("connectedWalletddress", accounts[0]); 
                 }).catch((error) => {
                           console.log("promise catches error! ");
                           console.error(error)
                           //alert(error);
                           
                              });
        return true;
     }
     
     else
     {    //alert('Please install Binance Wallet to your web browser');
           console.log("Please install BinanceChain Wallet");
           
          const Swal2 = Swal.mixin({
     customClass: {
       confirmButton: 'btn btn-success'
     },
     buttonsStyling: false
   });
   
    Swal2.fire({
           icon: 'warning',
           title: 'No wallet found',
           text: 'Please install Binance Wallet!',
           html: '<a href="https://www.bnbchain.org/en/binance-wallet" target="_blank" style="color: #00F; ">Click here to download Binance Wallet</a> ',
           confirmButtonText: 'Got it !'
       }); 
     }
     return false;
   };
   const disconnect = async () => { await BinanceChain.request({
           method: "wallet_requestPermissions",
           params: [{
               eth_accounts: {}
           }]
       }).then((result)=>{ ethEnabled(); }); };
   
   function connectBinance(){
    //console.log(window.BinanceChain);
    
   // alert(BinanceChain.chainId);
   if(!window.BinanceChain)
   {
   console.log("Please install BinanceChain Wallet");
           
          const Swal2 = Swal.mixin({
     customClass: {
       confirmButton: 'btn btn-success'
     },
     buttonsStyling: false
   });
   
    Swal2.fire({
           icon: 'warning',
           title: 'No wallet found',
           text: 'Please install Binance Wallet!',
           html: '<a href="https://www.bnbchain.org/en/binance-wallet" target="_blank" style="color: #00F; ">Click here to download Binance Wallet</a> ',
           confirmButtonText: 'Got it !'
       }); 
   }
   else
   {
   window.BinanceChain.request({ method: 'eth_chainId' })
                           .then((chain_id) => {
                            ethEnabled();
                                        console.log(chain_id);
                                        //alert(chain_id);
                                            if(chain_id == 0x01 || chain_id == 0x38)
                                         {
                                                   ethEnabled();
                                             }
                                           else
                                           {
                                            Swal.fire("This app currently support only BinanceChain and Binance Smart Chain ! Please change network");      
                                            
                                           }   
     
                                       }).catch((error) => {
                                                   console.log("promise catches error! ");
                                                   console.error(error)
                                                   });
    }                                               
   
   }
  
   const getCurrentWalletConnected = async() => {
     if(typeof window !="undefined" || typeof window.BinanceChain !="undefined" ){
      try {
       // alert(1211111);
        // Metamask installed 
        const accounts = await window.BinanceChain.request({ method: "eth_accounts"});
        if(accounts.length >0){
        console.log(accounts[0]);
        //alert("Your Metamask Wallet Connected Succesfully...")
       //document.getElementById('connectWallet').style.display = "none";
       //document.getElementsByClassName('modal-backdrop').style.display = "none";
       //document.getElementById('WalletButton').style.display = "none";
       $("#WalletButton").html('<div class="nav-link  pd-top-25 walletAddress">'+accounts[0].substring(0,5)+"..."+accounts[0].substring(38)+'</div>');
               
     //  window.location.reload();
       setWalletAddress(accounts[0]);
        } else{
         console.log("Connect Metamask using Connect button...");
        }
        
         }catch(err){
              console.error(err.message);
         }
     }else{
       
       console.log("Please install Binance Wallet ");
     }
  }
  


    return(  
        <>
        
        <a href="#" onClick={connectBinance}><img src="assets/img/binanceWallet.png" /> Binance Wallet</a>
        </>  
    )
}
