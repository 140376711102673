import React from 'react'
import { Link } from 'react-router-dom'
import Nav from '../components/Nav'
import FooterNav from '../components/FooterNav'
export default function Terms() {
  return (
     <React.Fragment>
         <Nav />

          
          <div className="about_banner">
              <div className="container">

                  <h1>Terms of Use</h1>

              </div>
          </div>
         
          <div className="home-about">
              <div className="container">
                  <div className="row">
                      
                      <div className="col-md-12 ">
                        <p>Welcome to Investandgrow.net ("Investandgrow," "we," "us," or "our"). These Terms of Use ("Terms") govern your use of the Investandgrow.net website (the "Site") and the associated interface (the "Interface"). By accessing or using the Site or Interface, you agree to be bound by these Terms. If you do not agree to these Terms, you may not access or use the Site or Interface.</p>
                        <ol>
                         <li>
                          <h1>Use of the Site and Interface</h1>
                          <p>The Investandgrow.net Site serves as an educational and informational resource for individuals interested in learning about the IAG Protocol and decentralized finance (DeFi). While we strive to provide accurate and up-to-date information, we cannot guarantee the completeness or accuracy of the content. The Site does not constitute financial, investment, or legal advice, and users should conduct their own research and consult with qualified professionals before making any financial decisions.
                            </p> <p>Additionally, the Interface provided by Investandgrow.net facilitates access to decentralized exchange services powered by the IAG Protocol. Users can engage in peer-to-peer transactions, swapping digital assets securely and efficiently.</p>
                         </li>  
                         <li>
                            <h1>Eligibility</h1>
                            <p>By accessing or using the Site or Interface, you represent and warrant that you are of legal age and have the legal capacity to enter into these Terms. If you are accessing the Site or Interface on behalf of a company or other legal entity, you represent that you have the authority to bind such entity to these Terms.
                              </p><p>Furthermore, users must comply with any geographic restrictions and eligibility criteria outlined by Investandgrow.net. Certain jurisdictions may impose legal restrictions on the use of decentralized exchange services, and it is the responsibility of users to ensure compliance with relevant laws and regulations.</p>
                            
                        </li> 
                        <li>
                            <h1>Compliance</h1>
                            <p>
                            You agree to comply with all applicable laws, regulations, and guidelines when accessing or using the Site or Interface. This includes, but is not limited to, laws governing cryptocurrency transactions, securities regulations, and anti-money laundering laws. You are solely responsible for ensuring that your use of the Site and Interface does not violate any domestic or foreign laws or regulations.
                            </p>
                            <p>
                            Moreover, users must adhere to the terms and conditions set forth by the IAG Protocol and any other protocols governing decentralized exchange transactions. Failure to comply with these requirements may result in the suspension or termination of access to the Site or Interface.
                            </p>
                        </li> 
                        <li>
                            <h1>Intellectual Property</h1>
                            <p>
                            Investandgrow.net owns all rights, names, logos, and marks used on the Site and Interface. Your use of the Site or Interface does not grant you any ownership or rights therein. You may not use, copy, reproduce, modify, distribute, or display any content from the Site or Interface without prior written consent from Investandgrow.net.
                            </p>
                            <p>
                            Furthermore, users must respect the intellectual property rights of third parties when accessing or using the Site or Interface. Any unauthorized use or reproduction of copyrighted materials may result in legal action being taken against the offending party.
                            </p>
                        </li> 
                        <li>
                            <h1>Modification and Termination</h1>
                            <p>Investandgrow.net reserves the right to modify, suspend, or terminate the Site or Interface at any time, with or without notice. We will not be liable for any losses or damages resulting from such actions. We also reserve the right to modify these Terms at any time, and any such modifications will be effective immediately upon posting on the Site. Your continued use of the Site or Interface after the posting of modified Terms constitutes your acceptance of the modifications.
                              </p> <p>Additionally, users should regularly review the Terms and any updates or modifications thereto. Continued use of the Site or Interface following changes to the Terms signifies acceptance of the revised terms and conditions.</p>
                        </li> 
                        <li>
                            <h1>Disclaimers</h1>
                            <p>The Site and Interface are provided on an "AS IS" and "AS AVAILABLE" basis. Investandgrow.net makes no guarantees regarding the functionality, accuracy, or availability of the Site or Interface. We disclaim all warranties, express or implied, including, but not limited to, warranties of merchantability, fitness for a particular purpose, and non-infringement.
                                </p> <p> Furthermore, users should be aware of the inherent risks associated with decentralized exchange services and cryptocurrency transactions. Market volatility, technological vulnerabilities, and regulatory uncertainty may impact the value and usability of digital assets traded on the Interface.</p>
                        </li> 
                        <li>
                            <h1>Limitation of Liability</h1>
                            <p>In no event shall Investandgrow.net be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Site or Interface. This includes, but is not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses, even if Investandgrow.net has been advised of the possibility of such damages.
                            </p> <p>Moreover, users acknowledge and accept that Investandgrow.net shall not be held liable for any losses incurred as a result of unauthorized access to user accounts, hacking attempts, or security breaches. Users are responsible for implementing appropriate security measures to safeguard their digital assets and personal information.</p>
                        </li> 
                        <li>
                            <h1>Indemnification</h1>
                            <p>You agree to indemnify and hold harmless Investandgrow.net, its affiliates, and their respective officers, directors, employees, and agents from any claims, damages, losses, liabilities, costs, or expenses arising out of or related to your use of the Site or Interface, your violation of these Terms, or your violation of any rights of any third party.
                            </p><p>Additionally, users are responsible for any legal fees, expenses, or damages incurred by Investandgrow.net as a result of claims or disputes arising from their actions or use of the Site or Interface. This indemnification obligation shall survive the termination or expiration of these Terms.</p>
                        </li> 
                        <li>
                            <h1>Prohibited Uses</h1>
                            <p>You agree not to engage in any prohibited uses of the Site or Interface, including but not limited to promoting or facilitating illegal activities, engaging in abusive trading practices, uploading malicious code, or engaging in harassment or impersonation. Additionally, you may not use the Site or Interface for any commercial purposes without prior written consent from Investandgrow.net.
                            </p> <p>Furthermore, users must refrain from engaging in any activities that may disrupt or interfere with the operation of the Site or Interface. This includes but is not limited to distributed denial-of-service (DDoS) attacks, spamming, or engaging in any other malicious behavior.</p>
                        </li> 
                        <li>
                            <h1>Privacy Policy</h1>
                            <p>Our Privacy Policy governs the collection, use, and disclosure of personal information provided by users of the Site and Interface. By accessing or using the Site or Interface, you consent to the collection, use, and disclosure of your personal information in accordance with our Privacy Policy. 
                                You can review our Privacy Policy <Link to='/privacy'>here</Link>.
                            </p> <p>Investandgrow.net is committed to protecting the privacy and confidentiality of user information. We utilize industry-standard security measures to safeguard personal data and prevent unauthorized access or disclosure.</p>
                        </li> 
                        <li>
                            <h1>Governing Law and Dispute Resolution</h1>
                            <p>These Terms shall be governed by and construed in accordance with the laws of St. Vincent and the Grenadines, without regard to its conflicts of law principles. Any dispute arising out of or relating to these Terms, the Site, or the Interface shall be resolved exclusively through binding arbitration in accordance with the rules of St. Vincent and the Grenadines. The arbitration shall be conducted in St. Vincent and the Grenadines, and the language of the arbitration shall be English. The decision of the arbitrator shall be final and binding upon the parties, and judgment upon the award rendered by the arbitrator may be entered in any court of competent jurisdiction.</p>
                            <p>Furthermore, users agree to waive their right to participate in class action lawsuits or class-wide arbitration proceedings. Any disputes arising from the interpretation or enforcement of these Terms shall be resolved on an individual basis, rather than collectively.</p>
                        </li> 
                        <li>
                            <h1>Contact Information</h1>
                            <p>
                            If you have any questions or concerns regarding these Terms or the use of the Site or Interface, please contact us at contact@investandgrow.net. Our support team is available to assist you with any inquiries or issues you may encounter.
                            </p>
                        </li>
                        <li>
                            <h1>Miscellaneous</h1>
                            <p>
                            These Terms constitute the entire agreement between you and Investandgrow.net regarding the use of the Site and Interface and supersede any prior agreements or understandings between the parties. No waiver of any provision of these Terms shall be deemed a further or continuing waiver of such provision or any other provision, and our failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision.
                            </p>
                            <p>
                            Investandgrow.net reserves the right to assign or transfer these Terms, in whole or in part, without restriction or notification. Users may not assign or transfer their rights or obligations under these Terms without the prior written consent of Investandgrow.net.
                            </p>
                        </li>
                        </ol>  

                      </div>
                  </div>
              </div>
          </div>
          




<FooterNav />
   </React.Fragment>
  )
}