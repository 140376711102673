import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Nav from '../components/Nav'
import FooterNav from '../components/FooterNav'
import * as $ from 'jquery'
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button';
import { useContext } from 'react';
import AppContext from '../components/AppContext';
export default function MyReward() {

    
   const myContext = useContext(AppContext);
    var chainindex = window.localStorage.getItem('selectedChain')?window.localStorage.getItem('selectedChain'):0;
    var walletadd = window.localStorage.getItem('connectedWalletddress')? window.localStorage.getItem('connectedWalletddress'):null;
    const [reward,setReward] = useState(0);
    const [receivedReward, setReceivedReward] = useState(0);
    const [claimStatus, setClaimStatus] = useState(0);
    
   

    function fetchRewards(wallet)
    {
         console.log("Fetching");
        $.post(myContext.apiLocation + "/backend/app/rewarddata.php",
        {
            flag:1,
          wallet: walletadd
        },function(data,status){
            console.log(data);
            if(status == 'success')
            {
                
                var jdata = JSON.parse(data);
                if(jdata.success)
                {
                setReward(jdata.reward);
                setReceivedReward(jdata.receivedreward);
                setClaimStatus(jdata.claimstatus);
                }
            }
        });
    } 
    function claimReward()
    {
        //alert("claiming");
        $.post("http://softquake.in/demo/dexapp/backend/app/rewarddata.php",
        {
            flag:2,
          wallet: walletadd
        },function(data,status){
            console.log(data);
            if(status == 'success')
            {
                var jdata = JSON.parse(data);
                console.log(jdata);
                if(jdata.success)
                {
                    setClaimStatus(jdata.claimstatus);
                }
               
            }
        });
    } 


    let claimbutton;
    claimbutton =  <button className="btn btn-primary swap-btn"  type="button" onClick={() => claimReward()}> Claim Reward</button>;
    let claimedbutton;
    claimedbutton = <button className="btn btn-warning swap-btn"  type="button" disabled> Reward Claimed! You will receive soon in your wallet</button>;
    let disbaledbutton = <button className="btn btn-default"  type="button"> <a href="/swap">Trade to get Reward</a></button>;
    useEffect(()=>{
	
        fetchRewards(walletadd)
    
      }, [reward, receivedReward, claimStatus])
  return (
     <React.Fragment>
         <Nav />

          
          <div className="home-about swap">
          <div className="container">
   <div className="row">
   
    <div className="col-md-6" style={{margin:'0 auto'}}>
     <div className="swap-body">
	    <h1>My Reward</h1>
        <div className="row">
		  <div className="swap-inner">

		    <div className="col-md-12">
		    
			<h6>Unclaimed Reward:</h6>
			</div>
			<div className="row clearfix">
			
			
			<div className="col-md-12">
			<h6>{reward} </h6>
			</div>
			
			</div>
			</div>
			
			<div className="row clearfix">
			<div className="col-md-12 text-center equal switchButton" >
			
			</div>
			</div>
			
			<div className="swap-inner">
				<div className="col-md-12">
					
				<h6>Total Received Reward</h6>
			</div>
			<div className="row clearfix">
			
			
			<div className="col-md-12">
			<h6>{receivedReward} </h6>
			</div>
			

				</div>
			</div>

			
			 
           {(claimStatus == 0)? ((reward > 0)? claimbutton : disbaledbutton) : claimedbutton}
			
			
		  </div>
		
		</div>

     </div>
    </div>   
  
</div>
</div>




<FooterNav />
   </React.Fragment>
  )
}
