import React from 'react'
import { Link } from 'react-router-dom'
import Nav from '../components/Nav'
import FooterNav from '../components/FooterNav'
export default function Privacy() {
  return (
     <React.Fragment>
         <Nav />

          
          <div className="about_banner">
              <div className="container">

                  <h1>Privacy Policy</h1>

              </div>
          </div>
         
          <div className="home-about">
              <div className="container">
                  <div className="row">
                      
                      <div className="col-md-12 ">
                        <ol>
                         <li>
                          <h1>Introduction</h1>
                          <p>Welcome to InvestAndGrow.net. This Privacy Policy governs the collection, use, and disclosure of information from users of our decentralized exchange (DEX). We are committed to protecting your privacy and ensuring the security of your data. By accessing or using our platform, you agree to comply with the terms outlined in this Privacy Policy.</p>
                         </li>  
                         <li>
                            <h1>Information Collection and Use</h1>
                            <p>At InvestAndGrow.net, we are dedicated to ensuring transparency in our data collection practices. We collect various types of information from users to facilitate the operation and improvement of our decentralized exchange (DEX). This information may include:</p>
                            <ul>
                                <li>
                                    <p><strong>Transaction Information:</strong> When users engage in transactions on our platform, we collect data related to these transactions. This includes details such as wallet addresses, transaction amounts, timestamps, and any associated metadata. Additionally, we may collect information about the tokens being traded, including token names, symbols, and smart contract addresses. This transaction data is essential for processing trades and ensuring the integrity of our DEX.</p>
                                </li>
                                <li>
                                    <p><strong>Communications:</strong> We may collect information from users when they communicate with us through various channels, such as customer support inquiries, feedback forms, or social media platforms. This information may include the content of communications, user identifiers, and any attachments provided by users. We use this information to respond to user inquiries, address concerns, and improve our services based on user feedback.</p>
                                </li>
                                <li>
                                    <p><strong>Technincal Information:</strong> We automatically collect technical information about users' devices and internet connections to enhance security and provide personalized experiences. This information may include:</p>
                                    <ul>
                                        <li>
                                            <p><strong>IP addresses:</strong> We collect users' IP addresses to identify their geographical location and ensure compliance with regional regulations. IP addresses may also be used for troubleshooting purposes and detecting and preventing fraudulent activities.</p>
                                        </li>
                                        <li>
                                            <p><strong>Browser Types:</strong> We gather data on the types of browsers used by our users to optimize our platform for compatibility and performance across different browser environments.</p>
                                        </li>
                                        <li>
                                            <p><strong>Operating systems:</strong> We collect information about users' operating systems to identify compatibility issues and ensure a seamless user experience across different devices and platforms.</p>
                                        </li>
                                        <li>
                                            <p><strong>Device Identifiers:</strong> We may collect unique device identifiers, such as mobile advertising identifiers or device MAC addresses, to personalize users' experiences and track their interactions with our platform.</p>
                                        </li>
                                        <li>
                                            <p><strong>Session Information:</strong> We track users' session durations, page views, and navigation paths to analyze usage patterns and trends, optimize our platform's performance, and improve user engagement.</p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p><strong>Cookies and Similar Technologies: </strong>We use cookies and similar technologies to enhance users' experiences on our platform and gather information about their usage patterns. Cookies are small text files stored on users' devices that enable us to recognize them and customize their experiences accordingly. We may use both session cookies, which are deleted after users close their browsers, and persistent cookies, which remain on users' devices for a specified period or until manually deleted. Cookies may be used for various purposes, including:</p>
                                    <ul>
                                        <li><p><strong>Authentication:</strong> We use cookies to authenticate users and verify their identity when they access our platform, allowing them to securely log in to their accounts and access personalized content.</p></li>
                                        <li><p><strong>Preferences: </strong> We use cookies to remember users' preferences and settings, such as language preferences and display options, to provide a customized user experience.</p></li>
                                        <li><p><strong>Analytics:</strong> We use cookies to collect data on users' interactions with our platform, such as page views, session durations, and click-through rates, to analyze usage patterns and trends and optimize our platform's performance and functionality.</p></li>
                                        <li><p><strong>Adevertising:</strong> We may use cookies to deliver targeted advertisements to users based on their interests, preferences, and browsing behavior, both on our platform and on third-party websites and online platforms.</p></li>
                                        <li><p><strong>Security:</strong> We use cookies to detect and prevent security threats and unauthorized access to users' accounts and personal information, such as phishing attacks and account takeover attempts.</p></li>
                                    </ul>
                                </li>
                            </ul>
                            <p>We utilize the information collected for purposes including:</p>
                            <p>
                            Facilitating and maintaining the functionality of our DEX <br />
                            Processing transactions and verifying user identity <br />
                            Providing customer support and responding to inquiries <br />
                            Analyzing usage patterns and trends to improve our platform <br />
                            Customizing users' experiences and providing personalized content and recommendations <br />
                            Conducting research and analysis to enhance our products and services <br />
                            Complying with legal and regulatory requirements, as applicable.<br />
                            </p>
                        </li> 
                        <li>
                            <h1>Third-party Services</h1>
                            <p>
                            We may engage third-party service providers to assist us in delivering and enhancing our DEX. These providers may have access to user information solely for the purpose of performing tasks on our behalf, such as hosting our platform, processing transactions, providing customer support, or analyzing user data. We ensure that all third-party service providers adhere to strict privacy and security standards. Additionally, users should review the privacy policies of these third-party providers for more information on how their data is handled.
                            </p>
                        </li> 
                        <li>
                            <h1>Data Security</h1>
                            <p>
                            We employ industry-standard measures to protect user information from unauthorized access, disclosure, alteration, or destruction. These measures include encryption, firewalls, access controls, and regular security audits. However, please note that no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security. Users can help protect their information by choosing strong, unique passwords and keeping their login credentials confidential.
                            </p>
                        </li> 
                        <li>
                            <h1>User Rights and Choices</h1>
                            <p>Users have certain rights and choices regarding their personal information, including the right to access, correct, or delete their information. Users can exercise these rights by contacting us using the contact information provided in this Privacy Policy. Additionally, users may have the option to adjust their privacy settings or preferences within their account settings. Users may also have the option to opt-out of certain data collection and processing activities, although this may affect their ability to access or use certain features on our platform.</p>
                        </li> 
                        <li>
                            <h1>Data Retention</h1>
                            <p>We retain user information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. We may retain certain information for longer periods for archival, legal, or regulatory purposes, or as necessary to resolve disputes, enforce our agreements, or protect our rights. Upon request, we will delete or anonymize user information in accordance with applicable laws and regulations.</p>
                        </li> 
                        <li>
                            <h1>International Data Transfers</h1>
                            <p>As a global platform, user information may be transferred to and processed in countries outside of the user's home country. These countries may have different data protection laws than the user's country of residence. By accessing or using our platform, users consent to the transfer and processing of their information in accordance with this Privacy Policy. We take steps to ensure that any international transfers of user information comply with applicable laws and regulations.</p>
                        </li> 
                        <li>
                            <h1>Children's Privacy</h1>
                            <p>Our DEX is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from individuals under the age of 18. If we become aware that we have inadvertently collected personal information from a minor, we will take steps to delete the information as soon as possible.</p>
                        </li> 
                        <li>
                            <h1>Marketing Communications</h1>
                            <p>We may use users' contact information to send them marketing communications about our products, services, promotions, and events. Users have the option to opt-out of receiving such communications by following the instructions provided in the communications or contacting us using the contact information provided in this Privacy Policy. Users may also have the option to adjust their communication preferences within their account settings.</p>
                        </li> 
                        <li>
                            <h1>Changes to this Privacy Policy</h1>
                            <p>We reserve the right to update or modify this Privacy Policy at any time to reflect changes in our business practices, legal requirements, or technological advancements. Any changes will be effective immediately upon posting the revised Privacy Policy on our platform. We encourage users to review this Privacy Policy periodically for any updates. Continued use of our platform after the posting of changes constitutes acceptance of the revised Privacy Policy.</p>
                        </li> 
                        <li>
                            <h1>Contact Us</h1>
                            <p>If you have any questions, concerns, or inquiries regarding this Privacy Policy or our privacy practices, please contact us at contact@investandgrow.net. We are committed to addressing and resolving any issues promptly and transparently.</p>
                        </li> 
                        </ol>  

                      </div>
                  </div>
              </div>
          </div>
          




<FooterNav />
   </React.Fragment>
  )
}