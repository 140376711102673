import React from 'react'
import { Link } from 'react-router-dom'
import Nav from '../components/Nav'
import FooterNav from '../components/FooterNav'
export default function About() {
  return (
     <React.Fragment>
         <Nav />

          
          <div className="about_banner">
              <div className="container">

                  <h1>About Us</h1>

              </div>
          </div>
         
          <div className="home-about">
              <div className="container">
                  <div className="row">
                      <div className="col-md-6 text-center">
                          <img src="assets/img/about2.png" className="mt-5 img-fluid" />

                      </div>
                      <div className="col-md-6 ">
                          <h1>Our Vision</h1>
                          <p>For the past century, the current financial system has not been able to deliver a system that protects all segments of society - a system that cannot be manipulated by the powerful or just work for a few, rather than the majority.</p>
                          <p>We need an alternative system based on sound technology like blockchain.</p>
                          <p>IAG’s vision is to continuously explore new technologies and ways to make our future secure for coming generations by ensuring a financial system that works for everyone.</p>
                          <p>To start, IAG is creating a decentralized exchange (DEX) that offers the best security, transparency, and anonymity to its customers.IAG offers a unique platform for trading cryptocurrency that follows the true spirit of crypto and blockchain technology: no market manipulation, no stored personal information, and no risk of an exchange losing your valued assets.</p>

                      </div>
                  </div>
              </div>
          </div>
          
          <div className="home-about">
              <div className="container">
                  <div className="row">

                      <div className="col-md-6 ">
                          <h1>Our Products </h1>
                          <p style={{ fontWeight: 'bold', textDecoration: 'underline'}}>IAG Decentralized Exchange (DEX) </p>
                      <p>The Invest and Grow DEX has been built on the Ethereum blockchain.The biggest benefit of Ethereum is a tried-and-true network that has been tested through years of operation and billions worth of value trading hands.It has a large and committed global community and the largest ecosystem in blockchain and cryptocurrency.A large community of Ethereum developers is constantly looking for new ways to improve the network and develop new applications.Because of Ethereum’s popularity, it tends to be the preferred blockchain network for new and exciting decentralized applications.</p>
                      <p>With IAG, users are able to trade all the Ethereum and Binance based coins and tokens.We will soon be adding other blockchains and their currencies to the platform as well.</p>



                  </div>
                  <div className="col-md-6 text-center">
                      <img src="assets/img/about1.png" className="mt-5 img-fluid" />
                  </div>
              </div>
          </div>
      </div><div className="home-about">
              <div className="container">
                  <div className="row">

                      <div className="col-md-6 ">
                          <p style={{ fontWeight: 'bold', textDecoration: 'underline'}}>IAG Token </p>
                      <p>The IAG token is a BEP-20 token, developed on the Binance smart chain.The total number of minted tokens will be 1 billion.Upon launch of the DEX platform, the IAG token will be launched as a utility token and will be given as rewards for transactions.Moving forward, we have big plans for the IAG token, and we can’t wait to share them with you.</p>


                  </div>
                  <div className="col-md-6 text-center">
                      <img src="assets/img/about3.png" className="img-fluid" />
                  </div>
              </div>
          </div>
</div>




<FooterNav />
   </React.Fragment>
  )
}
