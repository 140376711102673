import React from 'react'
import { HashRouter as Router,Routes, Route } from 'react-router-dom'
import Home from './Home'
import About from './About'
import HowItWorks from './HowItWorks'
import Faq from './Faq'
import Contact from './Contact'
import Swap from './Swap'
import MyReward from './MyRewards'
import Privacy from './Privacy'
import Terms from './Terms'

export default function Routerpage() {
  return (
    <div>
      <Router>
          <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/about' element={<About/>} />
          <Route path='/how-it-works' element={<HowItWorks/>} />
          <Route path='/faq' element={<Faq/>} />
          <Route path='/contact' element={<Contact/>} />
          <Route path='/myreward' element={<MyReward/>} />
          <Route path='/swap' element={<Swap/>} />
          <Route path='/privacy' element={<Privacy/>} />
          <Route path='/terms' element={<Terms/>} />
          </Routes>
      </Router>
    </div>
  )
}
