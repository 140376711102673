import React, { useState } from "react";

import { SignClient } from "@walletconnect/sign-client";
    import { Web3Modal } from "@web3modal/standalone";

import { useEffect } from "react";

const web3modal = new Web3Modal({
    projectId:'6cf0900ed317489e9564b45790195129',
    standaloneChains: ['eip155:1']
})


export default function WalletConnect() {
    
   const [signClient, setSignClient] = useState();
   const [sessions, setSessions] = useState([]);
   const [accounts, setAccounts] = useState([]);


   async function createClient(){
try{
  
    const client = await SignClient.init({
        projectId: '6cf0900ed317489e9564b45790195129'
    });
    console.log('client', client);
    setSignClient(client)

}catch(e){
    console.log(e );
}

   }

   async function connectWC(){ 
   if(!signClient) throw Error('can not connect');
     try{
        const proposalNamespace ={
            eip155:{
                chains: ["eip155:1"],
                methods: ["eth_sendTransaction"],
                events: ["connect","disconnect"]
            }
        };
        const {uri, approval} = await signClient.connect({
            requiredNamespaces: proposalNamespace
        })
         if(uri){
            window.$('#connectWallet').modal('hide');
            web3modal.openModal({uri})
            const sessionNamespace = await approval();
            onSessionConnect('sessionNamespace')
           // console.log('sessionNamespace', sessionNamespace);
         }
        console.log('uri', uri)
     }catch(e){
        console.log(e);
     }

   }

   async function onSessionConnect(session){
    if(!session) throw Error("Session not exist")
    try{
        setSessions(session);
        setAccounts(session.namespaces.eip155.accounts[0].slice(9));
    }catch(e){
        console.log(e);
    }
   }

   useEffect(() =>{
    if(!signClient){
        createClient();
    }
   },[signClient]);
    
    
    

  return (
    
    <a href="#" onClick={connectWC}><img src="assets/img/walletconect.png" /> WalletConect</a>
    
  )
}