import React from 'react'
import { Link } from 'react-router-dom'
export default function FooterNav() {
  return (
   <div>
    <footer className="footer">
          <div className="container bottom_border">
              <div className="row">
                  <div className=" col-sm-4 col-md col-sm-4  col-12 col">
                      <h5 className="headin5_amrc col_white_amrc pt2"></h5>

                      <Link to="/" className="navbar-brand" ><img src="assets/img/logo.png" /></Link>


                  </div>


                  <div className=" col-sm-4 col-md  col-6 col">
                      <h5 className="headin5_amrc col_white_amrc pt2"></h5>

                      <ul className="footer_ul_amrc">
                          <li><Link to="/">HOME</Link></li>
                          <li><Link to="/swap">SWAP TOKEN</Link></li>
                          <li><Link to="/about">ABOUT US</Link></li>
                      </ul>

                  </div>


                  <div className=" col-sm-4 col-md  col-6 col">
                      <h5 className="headin5_amrc col_white_amrc pt2"></h5>

                      <ul className="footer_ul_amrc">
                          <li><Link to="/faq">FAQ</Link></li>
                          <li><Link to="/how-it-works">HOW IT WORKS</Link></li>
                          <li><Link to="/contact">CONTACT US</Link></li>
                      </ul>

                  </div>


                  <div className=" col-sm-4 col-md  col-12 col">
                      <h5 className="headin5_amrc col_white_amrc pt2">Follow Us:</h5>

                      <div className="row">
                          <div className="col-xs-2 col-md-2 col-2 text-center">
                              <a href="https://www.pinterest.ca/investandgrow22/" target="_blank"><img src="assets/img/pin.png" className="img-fluid" /></a>
                          </div>

                          <div className="col-xs-2 col-md-2 col-2 text-center">
                              <a href="https://twitter.com/InvestandGrow22" target="_blank"><img src="assets/img/twitter.png" className="img-fluid" /></a>
                          </div>
                          <div className="col-xs-2 col-md-2 col-2 text-center">
                              <a href="https://www.linkedin.com/in/invest-and-grow-287681250/" target="_blank"><img src="assets/img/ln.png" className="img-fluid" /></a>
                          </div>
                          <div className="col-xs-2 col-md-2 col-2 text-center">
                              <a href="https://www.instagram.com/investandgrow2022/" target="_blank"><img src="assets/img/ins.png" className="img-fluid" /></a>
                          </div>


                      </div>

                  </div>
              </div>
          </div>


          <div className="container">
              <ul className="foote_bottom_ul_amrc">
                  <li><Link to="/privacy">Privacy Policy</Link></li>
                  <li><Link to="/terms">Terms Of Use</Link></li>
              </ul>

              <p className="text-left">Copyright @2023 IAG. All rights reserved.</p>
          </div>



      </footer>
     

          </div>
          
  )
}
