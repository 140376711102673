import * as React from 'react';
import { forwardRef, useRef, useImperativeHandle } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

function modify(){
  console.log("MODIFY");
}
export default function ComboBox(parameters) {
  
  var tokens = parameters.token;
	var options = [];
	options = tokens?.map((e,i) => {
		 return( {"label":tokens[i].name, "symbol":tokens[i].symbol, "value": i});
	})
  return (
    <Autocomplete
    popupIcon={<SearchOutlinedIcon sx={{color: '#666' }}/>}
      disablePortal
      autoHighlight
      id="combo-box-demo"
      options={options}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} onClick={ (e) => parameters.onChange(e,option.value) }>
          <img
            loading="lazy"
            width="20"
            srcSet={`${tokens[option.value].logoURI}`}
            src={`${tokens[option.value].logoURI}`}
            alt=""
          />
          { option.symbol} - {option.label} 
        </Box>
      )}
      sx={{ width: 465 }}
      renderInput={(params) => <TextField {...params}  label="Search More Tokens...." />}
      
    />
  );
}

