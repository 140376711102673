import React, { Component } from 'react'
import { useEffect, useState } from 'react'
import Web3 from 'web3'
import ERC20 from '../erc20abi.json'

function Balance(props){
  const [balance, setBalance] = useState(0);
  console.log("Balance checking started ===>>>" + props.wallet); 
  if(props.wallet)
  {
    var web3 = new Web3(Web3.givenProvider);
      if(props.type == 'tokens')
       {
        var tokenContract = new web3.eth.Contract(ERC20,props.contract);
        tokenContract.methods.balanceOf(props.wallet).call({from: props.wallet},function(error,result){
          console.log("Token Balance: " + result);
          tokenContract.methods.decimals().call({from: props.wallet},function(err,res){
          setBalance(result/(10**res));

          });
          
      });
      }
      else
       {
        web3.eth.getBalance(props.wallet).then((result)=>{
          console.log("Balance call returned: ");
          console.log(result);
          var bal = result;
          console.log("currency balance: ");
        setBalance(web3.utils.fromWei(bal.toString(),'ether'));
        }).catch((error) => {
          console.log("promise catches error! ");
          console.error(error)
        }); 
        
       } 
       
  }
  
  return(<span id={props.field}>{balance}</span>);
}

export default Balance