import React from 'react';
import './App.css';
import Routerpage from './pages/Routerpage';
import AppContext from './components/AppContext';
import { useEffect, useState } from 'react'
import Web3 from 'web3'
import Chains from './nativeCurrencyList.json'

function App() {
  // Define as many global variables as your app needs, and hooks 
  // to set the state of the variable.
  const apiloc = "https://www.investandgrow.net";
  const [currentProvider, setCurrentProvider] = useState();
  const [availableProviders, setAvailableProviders] = useState();
  const [chainIndex,setChainIndex]= useState(window.localStorage.getItem('selectedChain')?window.localStorage.getItem('selectedChain'):0); //0: Ethereum, 1: BSC, 2: Polygon
  const walletList = [{

         
  }];
  let providerDetails = [];
  const handleNewProviderDetail = (newProviderDetail) => {
    if (existsProviderDetail(newProviderDetail)) {
      return;
    }
    providerDetails.push(newProviderDetail);
    console.log(providerDetails);
    var counter =1;
    setAvailableProviders(providerDetails);
    providerDetails.forEach((providerDetail) => {
      const { info, provider: provider_ } = providerDetail;
  
      const content = JSON.stringify(
        {
          info,
          provider: provider_ ? '...' : provider_,
        },
        null,
        2,
      );
      
     /* if(content.info.name == "MetaMask")
      {
      setActiveProviderDetail(providerDetail);
      }*/

      /*
      const button = document.createElement('button');
button.className = 'btn btn-primary btn-lg btn-block mb-3';
button.innerHTML = `Use ${info.name}`;
button.onclick = () => {
  setActiveProviderDetail(providerDetail);
};
document.getElementById("testwalletbtn").appendChild(button);
      console.log(counter + " Content :===> ");
      counter++;          
      console.log(content);
      */
    });

  };

  const existsProviderDetail = (newProviderDetail) => {
    const existingProvider = availableProviders.find(
      (providerDetail) =>
        providerDetail.info &&
        newProviderDetail.info &&
        providerDetail.info.uuid === newProviderDetail.info.uuid,
    );
  
    if (existingProvider) {
      if (
        existingProvider.info.name !== newProviderDetail.info.name ||
        existingProvider.info.rdns !== newProviderDetail.info.rdns ||
        existingProvider.info.image !== newProviderDetail.info.image
      ) {
        console.error(
          `Received new ProviderDetail with name "${newProviderDetail.info.name}", rdns "${newProviderDetail.info.rdns}, image "${newProviderDetail.info.image}, and uuid "${existingProvider.info.uuid}" matching uuid of previously received ProviderDetail with name "${existingProvider.info.name}", rdns "${existingProvider.info.rdns}", and image "${existingProvider.info.image}"`,
        );
      }
      console.log(
        `Ignoring ProviderDetail with name "${newProviderDetail.info.name}", rdns "${newProviderDetail.info.rdns}", and uuid "${existingProvider.info.uuid}" that was already received before`,
      );
      return true;
    }
    return false;
  };

  const detectEip6963 = () => {
    window.addEventListener('eip6963:announceProvider', (event) => {
      if (event.detail.info.uuid) {
        //eip6963Warning.hidden = true;
        //eip6963Section.hidden = false;
        //console.log(event.detail.info);
        //console.log(event.detail.info.uuid);
        handleNewProviderDetail(event.detail);
      }
      else{
        console.log("Not detected");
      }
    });
  
    window.dispatchEvent(new Event('eip6963:requestProvider'));
  };

  useEffect(()=>{
  detectEip6963();
  },[]);

  const connectWallet = (infoname) =>
  {
    availableProviders.forEach((providerDetail) => {
      const { info, provider: provider_ } = providerDetail;
      if(info.name == infoname)
       setCurrentProvider(providerDetail.provider);
    });
  }
  const appSettings = {
    apiLocation: apiloc,
    availableProvidersArray: availableProviders,
    chainIndex: chainIndex,
    currentProvider : currentProvider,
    setChainIndex,
    setCurrentProvider
    
  };
  return (
    <AppContext.Provider value={appSettings}> 
    <div className="App">
      <Routerpage/>
    </div>
    </AppContext.Provider>
  );
}

export default App;
