import React from 'react'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import Web3 from 'web3';
import $ from 'jquery'
const { ethereum } = window;
export default function SafePal(){
  
  const [walletAddress, setWalletAddress] = useState("");
   useEffect(()=>{
    getCurrentWalletConnected();
   });

    const ethEnabled = async () => {
      if (window?.isSafePal){
          console.log("Safe Pal provider connectivity testing --->");
         //console.log(window.SafePalProvider.request({ method: 'eth_requestAccounts' }));
        window.safepalProvider.request({ method: 'eth_requestAccounts' }).then((accounts) => 
        { console.log(accounts);
          window.$('#connectWallet').modal('hide'); 
          if($("#WalletButton").length)
                 
          //$("#WalletButton").html('<a href="connect-wallet.php" class="default-btn move-right"><span>Connected</span> </a>');
          $("#WalletButton").html('<div class="nav-link  pd-top-25 walletAddress">'+accounts[0].substring(0,5)+"..."+accounts[0].substring(38)+'</div>');
          
        }).catch((error) => 
                            { console.log(error);});
      }
      else
      {
            console.log("Safepal Wallet is not available");
          const Swal2 = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success'
      },
      buttonsStyling: false
    });
    
     Swal2.fire({
            icon: 'warning',
            title: 'No wallet found',
            text: 'SafePal Wallet is not available',
            html: '<a href="https://www.safepal.com/en/download" target="_blank" style="color: #00F; ">Click here to download SafePal Wallet</a> ',
            confirmButtonText: 'Got it !'
        });
      }
      return false;
    };
    const disconnect = async () => { await window.safepalProvider.request({
            method: "wallet_requestPermissions",
            params: [{
                eth_accounts: {}
            }]
        }).then((result)=>{ ethEnabled(); }); };
    
    function connectSafe(){
      if (window?.isSafePal){  
        console.log("Safepal Wallet "); 
         
                     console.log(ethereum.chainId);
                     var chain_id = ethereum.chainId;
                     //alert(chain_id);
                         if(chain_id == 0x1 || chain_id == 56 || chain_id == 137)
                          {
                                ethEnabled();
                          }
                        else
                        {
                         Swal.fire("This app currently support only Ethereum Mainnet or Binance Smart Chain or Polygon Mainnet! Please change network").then(function(isConfirm){
                          
                            // check if the chain to connect to is installed
                            ethereum.request({
                              method: 'wallet_switchEthereumChain',
                              params: [{ chainId: '0x38' }], // chainId must be in hexadecimal numbers
                            }).then(response => {response.json()}).catch (error => {
                              console.log("error after trying network switching => ");
                              console.log(error);
                              if (error.code === 4902) {
                              
                                  ethereum.request({
                                  method: 'wallet_addEthereumChain',
                                  params: [
                                    {
                                      chainId: '0x38',
                                      rpcUrls: ['https://bsc-dataseed1.bnbchain.org'],
                                      chainName: "BNB Smart Chain Mainnet",
                                      nativeCurrency: {
                                         name: "BNB",
                                         symbol: "BNB",
                                         decimals: 18
                                      },
                                      blockExplorerUrls: ["https://bscscan.com/"]
                                    }
                                  ]
                                }).then(response => {response.json()}).catch (addError => {
                                  console.log("error after trying to add network");
                                  console.error(addError);
                                }) 
                            }
                            }) 
                          

                         });      
                         
                         
                        }   

    
                 }
                 else{

                  console.log("Safepal Wallet is not available");
          const Swal2 = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success'
      },
      buttonsStyling: false
    });
    
     Swal2.fire({
            icon: 'warning',
            title: 'SafePal wallet not found',
            text: 'SafePal Wallet is not available',
            html: '<a href="https://www.safepal.com/en/download" target="_blank" style="color: #00F; ">Click here to download SafePal Wallet</a> ',
            confirmButtonText: 'Got it !'
        });
                 }
    }


    const getCurrentWalletConnected = async() => {
      if(typeof window != "undefined" && typeof window.safepal !="undefined" ){
       try {
         // Metamask installed 
         const accounts = await window.safepalProvider.request({ method: "tron_accounts"});
         console.log(accounts);
         
          }catch(err){
               console.log(err);
          }
      }else{
        
        console.log("Please install metamask ");
      }
   }
   

    return(  
        <>
        <a href="#" onClick={ disconnect }>Disconnect</a>
        <a href="#" onClick={connectSafe}><img src="assets/img/safepal.png" /> SafePal Wallet</a>
        </>  
    )
}