import React from 'react'
import { useEffect, useState } from 'react'
import swal from 'sweetalert';
import Swal from 'sweetalert';
import Web3 from 'web3';
import $ from 'jquery';

const { ethereum } = window;
//const isMathWalletInstalled = window.ethereum && window.ethereum.isMathWallet

export default function MathWallet(){
  
  const [walletAddress, setWalletAddress] = useState("");
   useEffect(()=>{
    getCurrentWalletConnected();
   });

    const ethEnabled = async () => {
      if (window.ethereum?.isMathWallet){
        //alert(1111);
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
      .then((accounts) => {
                window.web3 = new Web3(window.ethereum);
                console.log(accounts);
                window.$('#connectMath').modal('hide');
                //$('#connectMath').modal('hide')
               // $('#connectMath').modal().hide();
              //  $("#walletAddress").html(accounts[0].substring(0,5)+"..."+accounts[0].substring(38));
                if($("#WalletButton").length)
                 
                //$("#WalletButton").html('<a href="connect-wallet.php" class="default-btn move-right"><span>Connected</span> </a>');
                $("#WalletButton").html('<div class="nav-link  pd-top-25 walletAddress">'+accounts[0].substring(0,5)+"..."+accounts[0].substring(38)+'</div>');
                
            
                  }).catch((error) => {
                            console.log("promise catches error! ");
                            console.error(error)
                            //alert(error);
                            
                               });
         return true;
      }
      else
      {
            console.log("Please install metamask");
            alert('Please install Math Wallet to your web browser');
          /*  const Swal2 = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success'
      },
      buttonsStyling: false
    });
    
     Swal2.fire({
            icon: 'warning',
            title: 'No wallet found',
            text: 'Please install Metamask!',
            html: '<a href="https://metamask.io/download.html" target="_blank" style="color: #00F; ">Click here to download Metamask</a> ',
            confirmButtonText: 'Got it !'
        }); */
      }
      return false;
    };
    const disconnect = async () => { await ethereum.request({
            method: "wallet_requestPermissions",
            params: [{
                eth_accounts: {}
            }]
        }).then((result)=>{ ethEnabled(); }); };
     function connectMath(){
           ethEnabled();
     }
  /*  function connectMath(){
      
    ethereum.request({ method: 'eth_chainId' })
                            .then((chain_id) => {
                              alert(1);
                                         console.log(chain_id);
                                         //alert(chain_id);
                                             if(chain_id == 1 || chain_id == 0x1 || chain_id == 0x01 || chain_id == 56)
                                          {
                                                    ethEnabled();
                                              }
                                            else
                                            {
                                             // Swal.fire("This app currently support only Mainnet or Rinkeby Testnet ! Please change network");      
                                             alert('This app currently support only Ethereum and Binance Smart Chain ! Please change network');
                                            }   
      
                                        }).catch((error) => {
                                                    console.log("promise catches error! ");
                                                    console.error(error)
                                                    });
    
    }
*/

    const getCurrentWalletConnected = async() => {
      if(typeof window != "undefined" && typeof window.ethereum !="undefined" ){
       try {
         // Metamask installed 
         const accounts = await window.ethereum.request({ method: "eth_accounts"});
         
         if(accounts.length >0){
         console.log(accounts[0]);
         //alert("Your Metamask Wallet Connected Succesfully...")
        //document.getElementById('connectWallet').style.display = "none";
        //document.getElementsByClassName('modal-backdrop').style.display = "none";
        //document.getElementById('WalletButton').style.display = "none";
        $("#WalletButton").html('<div class="nav-link  pd-top-25 walletAddress">'+accounts[0].substring(0,5)+"..."+accounts[0].substring(38)+'</div>');
        window.$('#connectMath').modal('hide');  
      //  window.location.reload();
        setWalletAddress(accounts[0]);
         } else{
          console.log("Connect Metamask using Connect button...");
         }
         
          }catch(err){
               console.error(err.message);
          }
      }else{
        
        console.log("Please install metamask ");
      }
   }




    return(  
        <>
        <a href="#" onClick={connectMath}><img src="assets/img/mathwallet.png" /> Math Wallet</a>
        </>  
    )
}