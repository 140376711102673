import React from 'react'
import { Link } from 'react-router-dom'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import FooterNav from '../components/FooterNav'
export default function Home() {
  return (
      <React.Fragment>
          <Nav />

          <div className="banner">
              <div className="container">
                  <div className="row">
                      <div className="col-md-6">
                          <h1>Swap Tokens With Great Confidence</h1>
                         
                          <h2>IAG offers a user-friendly decentralized exchange so that you can swap or trade your coins and tokens without any prior crypto exchange experience.If you need more information, refer to our section “How the exchange works” and you’ll be ready to confidently trade crypto in just minutes.</h2>

                      </div>
                      <div className="col-md-6">
                         
                         <Helmet><script src="https://widgets.coingecko.com/coingecko-coin-price-chart-widget.js"></script></Helmet>
<coingecko-coin-price-chart-widget  coin-id="bitcoin" currency="usd" height="300" locale="en" background-color="#f0c0fd"></coingecko-coin-price-chart-widget>



                      </div>

                  </div>

              </div>
          </div>

          <div className="container">
              <div className="row">
                  <div className="col-md-12">
                  <Helmet> <script defer src="https://www.livecoinwatch.com/static/lcw-widget.js"></script></Helmet> <div className="livecoinwatch-widget-5" lcw-base="USD" lcw-color-tx="/999999" lcw-marquee-1="coins" lcw-marquee-2="movers" lcw-marquee-items="10"></div>
                  </div>

              </div>
          </div>

          <div className="counter">
              <div className="container">
                  <div className="row">

                      <div className="col-md-4">
                          <h1>Fast & easy</h1>
                          <p>Trade every unique coin and token based on Ethereum and Binance quickly and simply.</p>
                      </div>

                      <div className="col-md-4">
                          <h1>Safe Secure</h1>
                          <p>IAG provides a secure environment with robust security protocols so you can trade with peace of mind.</p>
                      </div>

                      <div className="col-md-4">
                          <h1>Spend less, Earn more</h1>
                          <p>IAG’s exchange and gas fees are highly competitive.</p>
                      </div>

                  </div>
              </div>
          </div>

          <div className="home-about">
              <div className="container">
                  <div className="row">
                      <div className="col-md-6 text-center">
                          <img src="assets/img/about-home1.png" alt="about IAG" />

                      </div>
                      <div className="col-md-6 ">
                          <h1>Your Wallet, Your Crypto.</h1>
                          <p>Because IAG Exchange is decentralized, you are always in control of your crypto.Unlike other exchanges, when you use IAG, your assets are stored in your own personal wallet, so there is no risk of losing your coins or tokens in the event of a cyber attack on the exchange.</p>

                      </div>
                  </div>
              </div>
          </div>


          <div className="home-about">
              <div className="container">
                  <div className="row">

                      <div className="col-md-6 ">
                          <h1>Build Wealth With <span style={{ color: '#ea4bea' }}>IAG</span>.</h1>
                          <p>Trading with IAG will help sustainably build your wealth over time thanks to our low fees and rates.Take control of your financial future today! </p>
                      </div>
                      <div className="col-md-6 text-center">
                          <img src="assets/img/about-home2.png" alt="about IAG" />
                      </div>
                  </div>
              </div>
          </div>




          <FooterNav />
</React.Fragment>
  )
}
